//==============================
// Page
//==============================

.article {
  padding: 60px 0 40px;
  min-height: calc(100vh - #{$header-height} - #{$footer-height});

  @include bp(s) {
    min-height: calc(100vh - #{$header-bar-height} - #{$footer-height-s});
  }

  @include bp(xs) {
    padding: 40px 0 20px;
    min-height: calc(100vh - #{$header-bar-height} - #{$footer-height-xs});
  }

  &-content {
    width: calc(100% - 80px);
    max-width: 1000px;
    margin: 0 auto;
    padding-bottom: 100px;

    @include bp(s) {
      width: calc(100% - 60px);
    }

    h1 {
      @include title1;
      margin-bottom: .5em;
    }

    h2 {
      @include title2;
    }

    h3 {
      @include title3;
    }

    h4 {
      @include title4;
    }

    h1,
    h2,
    h3,
    h4 {

      + h1,
      + h2,
      + h3,
      + h4 {
        margin-top: 1em;
      }

      + p,
      + ul,
      + ol {
        margin-top: .5em;
      }
    }

    p,
    ul,
    ol {
      @include text;

      + p,
      + ul,
      + ol {
        margin-top: 1em;
      }

      + h1,
      + h2,
      + h3,
      + h4 {
        margin-top: 1.5em;
      }
    }

    ul,
    ol {
      margin-left: 2em;
    }

    li {

      + li {
        margin-top: .25em;
      }
    }

    // a:not(.button) {
    //   color: currentColor;
    //   text-decoration: none;
    //   transition: color .3s;

    //   &:hover {
    //     color: $green;
    //   }
    // }
  }

  &-content {

    img {
      display: block;
      margin: 1.5em 0;
      max-width: 100%;
      height: auto;
    }

    blockquote {
      margin: 3em;
      padding: 0;

      @include bp(s) {
        margin: 1.5em 1em;
      }

      p {
      }
    }

    iframe {
      width: 100%;
      min-height: 535px;
    }

    .wp-block-image {
      margin-left: 0;
      margin-right: 0;
    }

    pre {
      overflow: auto;
      padding: 20px;
      background-color: #f0f0f0;
    }

    &-accordion {
      margin-top: 20px;

      .accordion-link {
        margin-top: 15px;
      }

      .accordion-target {
        margin-top: .3em;
      }
    }
  }
}
