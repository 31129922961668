//==============================
// Typography
//==============================

h1, h2, h3, h4, h5, h6, p, ul, ol, li, dl, dt, dd {
  margin: 0;
  padding: 0;
}

@mixin title1 {
  font: 700 normal 50px/1.1 $font-primary;
  color: $green;

  @include bp(l) {
    font-size: 48px;
  }

  @include bp(m) {
    font-size: 44px;
  }

  @include bp(s) {
    font-size: 38px;
  }
}

.title1 {
  @include title1;

   + p,
   + .text,
   + h4 {
    margin-top: 1em;
   }
}

@mixin title2 {
  font: 700 normal 40px/1.1 $font-primary;
  color: $green;

  @include bp(l) {
    font-size: 38px;
  }

  @include bp(m) {
    font-size: 34px;
  }

  @include bp(s) {
    font-size: 30px;
  }
}

.title2 {
  @include title2;

  + p,
  + .text {
    margin-top: .5em;
  }
}

@mixin title3 {
  font: 700 normal 24px/1.3 $font-primary;
  color: #555;

  @include bp(m) {
    font-size: 21px;
  }

  @include bp(s) {
    font-size: 20px;
  }
}

.title3 {
  @include title3;

  + p,
  + .text {
    margin-top: .3em;
  }
}

@mixin title4 {
  font: 700 normal 20px/1.4 $font-primary;
  letter-spacing: .02em;
  color: #333;

  @include bp(l) {
    font-size: 19px;
  }

  @include bp(m) {
    font-size: 18px;
  }

  @include bp(s) {
    font-size: 17px;
  }
}

.title4 {
  @include title4; 

  + p,
  + .text {
    margin-top: .3em;
  }
}

@mixin text {
  font: 400 normal 18px/1.5 $font-primary;
  color: #555;

  @include bp(s) {
    font-size: 17px;
  }
}

p,
.text {
  @include text;

  a {
    color: inherit;
    text-decoration: none;
    transition: color .3s;
    border-bottom: 1px solid currentColor;

    &:hover {
      color: $green;
    }
  }

  + h4 {
    margin-top: 1.5em;
  }
}

ol,
ul {

  &.text {
    margin-left: 1.2em;
  }
}

@mixin meta {
  font: 500 normal 15px/1.6 $font-primary;
  text-transform: uppercase;
  letter-spacing: .01em;
}

.meta {
  @include meta;
}

address {
  font-style: normal;
}

.tracking-fix {
  letter-spacing: -.08em;
}
