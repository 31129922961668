//==============================
// Fonts
//==============================

// Stoneburg
@include font('Stoneburg', 'Stoneburg-Regular.woff', 400, normal);

// Fieldwork
@include font('Fieldwork', 'fieldwork-geo-light.woff', 400, normal);
@include font('Fieldwork', 'fieldwork-italic-light.woff', 400, italic);
@include font('Fieldwork', 'fieldwork-geo-demibold.woff', 700, normal);
@include font('Fieldwork', 'fieldwork-italic-demibold.woff', 700, italic);
@include font('Fieldwork', 'fieldwork-geo-black.woff', 800, normal);
