//==============================
// Contact
//==============================


// WP Forms Lite
.contact {

  &-form {
    width: calc(100% - 80px);
    max-width: 800px;
    margin: 0 auto 100px;
    @include text;

    div.wpforms-container-full button[type=submit] {
      font: 700 normal 18px/1.2 $font-primary !important;
      letter-spacing: .02em !important;
      text-decoration: none !important;
      color: $white !important;
      background-color: $green !important;
      display: inline-block !important;
      padding: .9em 2em !important;
      border: 0 !important;
      transition: background-color .3s, color .3s !important;
      text-align: center !important;
      border-radius: 30px !important;
      height: auto !important;

      &:hover {
        background-color: darken($green, 10%) !important;
        color: $white !important;
        cursor: pointer !important;
        outline: 0 !important;
      }
    }
  }
}


// Contact Form 7
.wpcf7-form {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  div {
    margin-bottom: $form-gutter * 2;

    @include bp(s) {
      margin-bottom: $form-gutter;
    }

    &.third {
      width: calc((100% / 3) - #{$form-gutter * 3} / 2);

      @include bp(s) {
        width: 100%;
      }
    }

    &.half {
      width: calc(50% - #{$form-gutter});

      @include bp(s) {
        width: 100%;
      }
    }

    &.full {
      width: 100%;
    }
  }

  label,
  .wpcf7-list-item-label {
    @include text;
  }

  label {
    margin: .7em 0 .2em !important;
    display: block;

    + br {
      display: none;
    }
  }

  .wpcf7-list-item {
    display: block !important;
    margin: .2em 0 0 0 !important;

    &.first {
      margin-top: 0.5em !important;
    }

    .wpcf7-list-item-label {
      margin-left: 0.3em;
    }
  }

  input[type="text"],
  input[type="email"],
  input[type="tel"],
  select,
  textarea {
    @include input-reset;
    width: 100%;
    margin: 0;
    padding: .8em 1em .7em;
    background-color: $white;
    @include text;
    color: $charcoal;
    transition: border-color .3s;
    border-radius: 3px;
    border: #e0e0e0 solid 2px;

    &:focus {
      outline: 0;
      border-color: $green;
    }
  }

  select {
    background-image: url('assets/images/global/select-arrow.svg');
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: right center;
    padding-right: 3em;
  }

  textarea {
    resize: none;
    height: 200px;
  }

  input[type="submit"] {
    margin: 20px auto 0;
    display: block;
  }
}

.form {
  padding-bottom: 40px;

  &.fill {
    background-color: $light-grey;
    margin-bottom: 100px;

    @include bp(s) {
      margin-bottom: 60px;
    }
  }

  &-intro {
    padding: 60px 80px 40px;
    margin: 0 auto;
    width: calc(100% - 80px);
    max-width: 800px;
    text-align: center;

    @include bp(s) {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
