//==============================
// Intro
//==============================

.intro {
  padding: 65px 140px 80px;
  margin: 0 auto;
  width: calc(100% - 80px);
  max-width: 840px;
  position: relative;

  @include bp(m) {
    max-width: 700px;
    padding: 60px 100px 70px;
  }

  @include bp(s) {
    padding: 60px;
  }

  @include bp(xs) {
    width: calc(100% - 30px);
    padding: 50px 30px;
  }

  &.center {
    text-align: center;
  }

  &.fill {
    margin: 100px auto;
    border-radius: 20px;
    background-color: $light-grey;
    // box-shadow: 0 10px 50px -10px rgba(0,0,0,.2);

    @include bp(s) {
      border-radius: 10px;
      margin: 60px auto 40px;
      border-radius: 5px;
      // box-shadow: 0 5px 40px -5px rgba(0,0,0,.2);
    }
  }

  &-icon {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);
    width: 80px;
    height: 80px;
    background-color: $green;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    @include bp(s) {
      height: 60px;
      width: 60px;
    }

    img {
      width: auto;
      height: auto;
      max-width: 50%;
      max-height: 80%;
    }
  }
}
