//==============================
// Tiles
//==============================

.tiles {

  &-items {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 30px;

    @include bp(xxl) {
      max-width: 1200px;
    }

    @include bp(l) {
      max-width: 760px;
    }

    @include bp(s) {
      max-width: 500px;
      padding-top: 40px;
    }
  }

  &-item {
    width: calc((100% / 4) - (#{$tile-gutter * 3} / 4));
    margin-right: $tile-gutter;
    margin-bottom: $tile-gutter;

    @include bp(l) {
      width: calc((100% / 2) - (#{$tile-gutter} / 2));
    }

    @include bp(s) {
      width: 100%;
      margin-right: 0;
    }

    &:nth-child(4n) {
      margin-right: 0;
    }

    &:nth-child(2n) {

      @include bp(l) {
        margin-right: 0;
      }
    }

    &-icon {
      display: block;
      margin-bottom: 20px;

      img {
        display: block;
        height: 50px;
        width: auto;
      }
    }
  }
}
