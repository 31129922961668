//==============================
// Grid
//==============================

.grid {
  padding: 0 80px 40px;

  @include bp(l) {
    padding: 40px 30px 0;
  }

  @include bp(s) {
    padding: 30px 20px 0;
  }

  &-header {
    padding-bottom: 80px;
    text-align: center;
    max-width: 800px;
    margin: 0 auto;

    @include bp(s) {
      padding-bottom: 60px;
    }
  }

  &-items {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    margin: 0 auto;
    max-width: 1200px;

    .grid-item {
      width: calc((100% / 3) - (#{$grid-gutter * 2} / 3));
      margin-right: $grid-gutter;

      @include bp(m) {
        width: calc(50% - (#{$grid-gutter} / 2));
      }

      @include bp(s) {
        width: 100%;
        margin-right: 0;
      }

      &:nth-child(3n) {
        margin-right: 0;

        @include bp(m) {
          margin-right: $grid-gutter;
        }

        @include bp(s) {
          margin-right: 0;
        }
      }

      &:nth-child(2n) {

        @include bp(m) {
          margin-right: 0;
        }
      }
    }
  }

  &-item {
    margin-bottom: 80px;
    position: relative;
    // text-align: center;

    @include bp(s) {
      max-width: 400px;
      margin-bottom: 70px;
    }

    @include bp(xs) {
      margin-right: 0;
    }

    > img {
      width: 100%;
      height: auto;
      display: block;
      margin-bottom: 20px;
    }

    &-img {
      display: block;
      height: 300px;
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      margin-bottom: 20px;
      border-radius: 5px;

      @include bp(xs) {
        height: 200px;
      }
    }

    &-content {
      padding: 0 10px;

      h2 + h3 {
        border-top: 0;
        padding-top: 0;
      }
    }

    &-icon {
      // width: 80px;
      height: 80px;
      margin: 0 auto 15px 10px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      @include bp(m) {
        height: 70px;
      }
      
      svg,
      img {
        width: auto;
        height: 100%;
      }
    }
  }
}
