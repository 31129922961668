/*!
Theme Name: The Sauce Pot Studios
Author: Robert Pessagno
Version: 3.1
Template: storefront
Description: This is a custom child theme for WooThemes StoreFront theme
*/
@font-face {
  font-family: "Stoneburg";
  src: url("assets/fonts/Stoneburg-Regular.woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Fieldwork";
  src: url("assets/fonts/fieldwork-geo-light.woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Fieldwork";
  src: url("assets/fonts/fieldwork-italic-light.woff");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Fieldwork";
  src: url("assets/fonts/fieldwork-geo-demibold.woff");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Fieldwork";
  src: url("assets/fonts/fieldwork-italic-demibold.woff");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "Fieldwork";
  src: url("assets/fonts/fieldwork-geo-black.woff");
  font-weight: 800;
  font-style: normal;
}
html {
  line-height: 1.15;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
}

article,
aside,
footer,
header,
nav,
section {
  display: block;
}

figcaption,
figure,
main {
  display: block;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted;
}

b,
strong {
  font-weight: inherit;
}

b,
strong {
  font-weight: bolder;
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

dfn {
  font-style: italic;
}

mark {
  background-color: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

audio,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

img {
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  display: inline-block;
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type=checkbox],
[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details,
menu {
  display: block;
}

summary {
  display: list-item;
}

canvas {
  display: inline-block;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

* {
  box-sizing: border-box;
}

body,
html {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

.main {
  padding-top: 120px;
}
.main:after {
  content: "";
  display: table;
  clear: both;
}
@media screen and (max-width: 1023px) {
  .main {
    padding-top: 90px;
  }
}

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.wpcf7-not-valid-tip {
  color: red;
  display: block;
  padding: 7px 0 5px;
  text-align: left;
  font: 400 normal 14px/1.5 "Fieldwork", Helvetica, Arial, sans-serif !important;
  letter-spacing: 0.02em;
}

.wpcf7-response-output {
  color: #111;
  border: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  text-align: center;
  width: 100%;
  clear: both;
  left: 0;
  font: 300 normal 18px/1.5 "Fieldwork", Helvetica, Arial, sans-serif !important;
}
@media screen and (max-width: 1023px) {
  .wpcf7-response-output {
    font-size: 17px !important;
  }
}
@media screen and (max-width: 767px) {
  .wpcf7-response-output {
    font-size: 16px !important;
  }
}
@media screen and (max-width: 480px) {
  .wpcf7-response-output {
    font-size: 15px !important;
  }
}

@keyframes loaderSpin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
span.ajax-loader {
  left: calc(50% - 8px);
  position: relative;
  margin: 10px 0 0 !important;
  display: block !important;
  background: none !important;
  border-radius: 50%;
  border-left: 2px solid #50c11e !important;
  border-top: 2px solid #50c11e !important;
  border-bottom: 2px solid transparent !important;
  border-right: 2px solid transparent !important;
  animation: loaderSpin 0.7s infinite linear;
}

/*! jQuery UI - v1.12.0 - 2016-08-30
* http://jqueryui.com
* Includes: core.css, datepicker.css, theme.css
* To view and modify this theme, visit http://jqueryui.com/themeroller/?scope=&folderName=base&cornerRadiusShadow=8px&offsetLeftShadow=0px&offsetTopShadow=0px&thicknessShadow=5px&opacityShadow=30&bgImgOpacityShadow=0&bgTextureShadow=flat&bgColorShadow=666666&opacityOverlay=30&bgImgOpacityOverlay=0&bgTextureOverlay=flat&bgColorOverlay=aaaaaa&iconColorError=cc0000&fcError=5f3f3f&borderColorError=f1a899&bgTextureError=flat&bgColorError=fddfdf&iconColorHighlight=777620&fcHighlight=777620&borderColorHighlight=dad55e&bgTextureHighlight=flat&bgColorHighlight=fffa90&iconColorActive=ffffff&fcActive=ffffff&borderColorActive=003eff&bgTextureActive=flat&bgColorActive=007fff&iconColorHover=555555&fcHover=2b2b2b&borderColorHover=cccccc&bgTextureHover=flat&bgColorHover=ededed&iconColorDefault=777777&fcDefault=454545&borderColorDefault=c5c5c5&bgTextureDefault=flat&bgColorDefault=f6f6f6&iconColorContent=444444&fcContent=333333&borderColorContent=dddddd&bgTextureContent=flat&bgColorContent=ffffff&iconColorHeader=444444&fcHeader=333333&borderColorHeader=dddddd&bgTextureHeader=flat&bgColorHeader=e9e9e9&cornerRadius=3px&fwDefault=normal&fsDefault=1em&ffDefault=Arial%2CHelvetica%2Csans-serif
* Copyright jQuery Foundation and other contributors; Licensed MIT */
/* Layout helpers
----------------------------------*/
.ui-helper-hidden {
  display: none;
}

.ui-helper-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.ui-helper-reset {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  line-height: 1.3;
  text-decoration: none;
  font-size: 100%;
  list-style: none;
}

.ui-helper-clearfix:before,
.ui-helper-clearfix:after {
  content: "";
  display: table;
  border-collapse: collapse;
}

.ui-helper-clearfix:after {
  clear: both;
}

.ui-helper-zfix {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0;
  filter: Alpha(Opacity=0); /* support: IE8 */
}

.ui-front {
  z-index: 100;
}

/* Interaction Cues
----------------------------------*/
.ui-state-disabled {
  cursor: default !important;
  pointer-events: none;
}

/* Icons
----------------------------------*/
.ui-icon {
  display: inline-block;
  vertical-align: middle;
  margin-top: -0.25em;
  position: relative;
  text-indent: -99999px;
  overflow: hidden;
  background-repeat: no-repeat;
}

.ui-widget-icon-block {
  left: 50%;
  margin-left: -8px;
  display: block;
}

/* Misc visuals
----------------------------------*/
/* Overlays */
.ui-widget-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ui-datepicker {
  width: 17em;
  padding: 0.2em 0.2em 0;
  display: none;
}

.ui-datepicker .ui-datepicker-header {
  position: relative;
  padding: 0.2em 0;
}

.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
  position: absolute;
  top: 2px;
  width: 1.8em;
  height: 1.8em;
}

.ui-datepicker .ui-datepicker-prev {
  left: 2px;
}

.ui-datepicker .ui-datepicker-next {
  right: 2px;
}

.ui-datepicker .ui-datepicker-prev span,
.ui-datepicker .ui-datepicker-next span {
  display: block;
  position: absolute;
  left: 50%;
  margin-left: -8px;
  top: 50%;
  margin-top: -8px;
}

.ui-datepicker .ui-datepicker-title {
  margin: 0 2.3em;
  line-height: 1.8em;
  text-align: center;
}

.ui-datepicker .ui-datepicker-title select {
  font-size: 1em;
  margin: 1px 0;
}

.ui-datepicker select.ui-datepicker-month,
.ui-datepicker select.ui-datepicker-year {
  width: 45%;
}

.ui-datepicker table {
  width: 100%;
  font-size: 0.9em;
  border-collapse: collapse;
  margin: 0 0 0.4em;
}

.ui-datepicker th {
  padding: 0.7em 0.3em;
  text-align: center;
  font-weight: bold;
  border: 0;
}

.ui-datepicker td {
  border: 0;
  padding: 1px;
}

.ui-datepicker td span,
.ui-datepicker td a {
  display: block;
  padding: 0.2em;
  text-align: right;
  text-decoration: none;
}

.ui-datepicker .ui-datepicker-buttonpane {
  background-image: none;
  margin: 0.7em 0 0 0;
  padding: 0 0.2em;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
}

.ui-datepicker .ui-datepicker-buttonpane button {
  float: right;
  margin: 0.5em 0.2em 0.4em;
  cursor: pointer;
  padding: 0.2em 0.6em 0.3em 0.6em;
  width: auto;
  overflow: visible;
}

.ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current {
  float: left;
}

/* with multiple calendars */
.ui-datepicker.ui-datepicker-multi {
  width: auto;
}

.ui-datepicker-multi .ui-datepicker-group {
  float: left;
}

.ui-datepicker-multi .ui-datepicker-group table {
  width: 95%;
  margin: 0 auto 0.4em;
}

.ui-datepicker-multi-2 .ui-datepicker-group {
  width: 50%;
}

.ui-datepicker-multi-3 .ui-datepicker-group {
  width: 33.3%;
}

.ui-datepicker-multi-4 .ui-datepicker-group {
  width: 25%;
}

.ui-datepicker-multi .ui-datepicker-group-last .ui-datepicker-header,
.ui-datepicker-multi .ui-datepicker-group-middle .ui-datepicker-header {
  border-left-width: 0;
}

.ui-datepicker-multi .ui-datepicker-buttonpane {
  clear: left;
}

.ui-datepicker-row-break {
  clear: both;
  width: 100%;
  font-size: 0;
}

/* RTL support */
.ui-datepicker-rtl {
  direction: rtl;
}

.ui-datepicker-rtl .ui-datepicker-prev {
  right: 2px;
  left: auto;
}

.ui-datepicker-rtl .ui-datepicker-next {
  left: 2px;
  right: auto;
}

.ui-datepicker-rtl .ui-datepicker-prev:hover {
  right: 1px;
  left: auto;
}

.ui-datepicker-rtl .ui-datepicker-next:hover {
  left: 1px;
  right: auto;
}

.ui-datepicker-rtl .ui-datepicker-buttonpane {
  clear: right;
}

.ui-datepicker-rtl .ui-datepicker-buttonpane button {
  float: left;
}

.ui-datepicker-rtl .ui-datepicker-buttonpane button.ui-datepicker-current,
.ui-datepicker-rtl .ui-datepicker-group {
  float: right;
}

.ui-datepicker-rtl .ui-datepicker-group-last .ui-datepicker-header,
.ui-datepicker-rtl .ui-datepicker-group-middle .ui-datepicker-header {
  border-right-width: 0;
  border-left-width: 1px;
}

/* Icons */
.ui-datepicker .ui-icon {
  display: block;
  text-indent: -99999px;
  overflow: hidden;
  background-repeat: no-repeat;
  left: 0.5em;
  top: 0.3em;
}

/* Component containers
----------------------------------*/
.ui-widget {
  font-family: "Fieldwork", Helvetica, Arial, sans-serif;
  font-weight: bold;
  letter-spacing: 0.05em;
  font-size: 1em;
}

.ui-widget .ui-widget {
  font-size: 1em;
}

.ui-widget input,
.ui-widget select,
.ui-widget textarea,
.ui-widget button {
  font-family: "Fieldwork", Helvetica, Arial, sans-serif;
  font-size: 1em;
}

.ui-widget.ui-widget-content {
  border: 1px solid #c5c5c5;
}

.ui-widget-content {
  border: 1px solid #dddddd;
  background: #ffffff;
  color: #333333;
}

.ui-widget-content a {
  color: #333333;
}

.ui-widget-header {
  border: 1px solid #dddddd;
  background: #e9e9e9;
  color: #333333;
  font-weight: bold;
}

.ui-widget-header a {
  color: #333333;
}

/* Interaction states
----------------------------------*/
.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default,
.ui-button,
html .ui-button.ui-state-disabled:hover,
html .ui-button.ui-state-disabled:active {
  border: 1px solid #c5c5c5;
  background: #f6f6f6;
  font-weight: normal;
  color: #454545;
}

.ui-state-default a,
.ui-state-default a:link,
.ui-state-default a:visited,
a.ui-button,
a:link.ui-button,
a:visited.ui-button,
.ui-button {
  color: #454545;
  text-decoration: none;
}

.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus,
.ui-button:hover,
.ui-button:focus {
  border: 1px solid #cccccc;
  background: #ededed;
  font-weight: normal;
  color: #2b2b2b;
}

.ui-state-hover a,
.ui-state-hover a:hover,
.ui-state-hover a:link,
.ui-state-hover a:visited,
.ui-state-focus a,
.ui-state-focus a:hover,
.ui-state-focus a:link,
.ui-state-focus a:visited,
a.ui-button:hover,
a.ui-button:focus {
  color: #2b2b2b;
  text-decoration: none;
}

.ui-visual-focus {
  box-shadow: 0 0 3px 1px rgb(94, 158, 214);
}

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
a.ui-button:active,
.ui-button:active,
.ui-button.ui-state-active:hover {
  border: 1px solid #003eff;
  background: #007fff;
  font-weight: normal;
  color: #ffffff;
}

.ui-icon-background,
.ui-state-active .ui-icon-background {
  border: #003eff;
  background-color: #ffffff;
}

.ui-state-active a,
.ui-state-active a:link,
.ui-state-active a:visited {
  color: #ffffff;
  text-decoration: none;
}

/* Interaction Cues
----------------------------------*/
.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight {
  border: 1px solid #dad55e;
  background: #fffa90;
  color: #777620;
}

.ui-state-checked {
  border: 1px solid #dad55e;
  background: #fffa90;
}

.ui-state-highlight a,
.ui-widget-content .ui-state-highlight a,
.ui-widget-header .ui-state-highlight a {
  color: #777620;
}

.ui-state-error,
.ui-widget-content .ui-state-error,
.ui-widget-header .ui-state-error {
  border: 1px solid #f1a899;
  background: #fddfdf;
  color: #5f3f3f;
}

.ui-state-error a,
.ui-widget-content .ui-state-error a,
.ui-widget-header .ui-state-error a {
  color: #5f3f3f;
}

.ui-state-error-text,
.ui-widget-content .ui-state-error-text,
.ui-widget-header .ui-state-error-text {
  color: #5f3f3f;
}

.ui-priority-primary,
.ui-widget-content .ui-priority-primary,
.ui-widget-header .ui-priority-primary {
  font-weight: bold;
}

.ui-priority-secondary,
.ui-widget-content .ui-priority-secondary,
.ui-widget-header .ui-priority-secondary {
  opacity: 0.7;
  filter: Alpha(Opacity=70); /* support: IE8 */
  font-weight: normal;
}

.ui-state-disabled,
.ui-widget-content .ui-state-disabled,
.ui-widget-header .ui-state-disabled {
  opacity: 0.35;
  filter: Alpha(Opacity=35); /* support: IE8 */
  background-image: none;
}

.ui-state-disabled .ui-icon {
  filter: Alpha(Opacity=35); /* support: IE8 - See #6059 */
}

/* Icons
----------------------------------*/
/* states and images */
.ui-icon {
  width: 16px;
  height: 16px;
}

/* positioning */
.ui-icon-blank {
  background-position: 16px 16px;
}

.ui-icon-caret-1-n {
  background-position: 0 0;
}

.ui-icon-caret-1-ne {
  background-position: -16px 0;
}

.ui-icon-caret-1-e {
  background-position: -32px 0;
}

.ui-icon-caret-1-se {
  background-position: -48px 0;
}

.ui-icon-caret-1-s {
  background-position: -65px 0;
}

.ui-icon-caret-1-sw {
  background-position: -80px 0;
}

.ui-icon-caret-1-w {
  background-position: -96px 0;
}

.ui-icon-caret-1-nw {
  background-position: -112px 0;
}

.ui-icon-caret-2-n-s {
  background-position: -128px 0;
}

.ui-icon-caret-2-e-w {
  background-position: -144px 0;
}

.ui-icon-triangle-1-n {
  background-position: 0 -16px;
}

.ui-icon-triangle-1-ne {
  background-position: -16px -16px;
}

.ui-icon-triangle-1-e {
  background-position: -32px -16px;
}

.ui-icon-triangle-1-se {
  background-position: -48px -16px;
}

.ui-icon-triangle-1-s {
  background-position: -65px -16px;
}

.ui-icon-triangle-1-sw {
  background-position: -80px -16px;
}

.ui-icon-triangle-1-w {
  background-position: -96px -16px;
}

.ui-icon-triangle-1-nw {
  background-position: -112px -16px;
}

.ui-icon-triangle-2-n-s {
  background-position: -128px -16px;
}

.ui-icon-triangle-2-e-w {
  background-position: -144px -16px;
}

.ui-icon-arrow-1-n {
  background-position: 0 -32px;
}

.ui-icon-arrow-1-ne {
  background-position: -16px -32px;
}

.ui-icon-arrow-1-e {
  background-position: -32px -32px;
}

.ui-icon-arrow-1-se {
  background-position: -48px -32px;
}

.ui-icon-arrow-1-s {
  background-position: -65px -32px;
}

.ui-icon-arrow-1-sw {
  background-position: -80px -32px;
}

.ui-icon-arrow-1-w {
  background-position: -96px -32px;
}

.ui-icon-arrow-1-nw {
  background-position: -112px -32px;
}

.ui-icon-arrow-2-n-s {
  background-position: -128px -32px;
}

.ui-icon-arrow-2-ne-sw {
  background-position: -144px -32px;
}

.ui-icon-arrow-2-e-w {
  background-position: -160px -32px;
}

.ui-icon-arrow-2-se-nw {
  background-position: -176px -32px;
}

.ui-icon-arrowstop-1-n {
  background-position: -192px -32px;
}

.ui-icon-arrowstop-1-e {
  background-position: -208px -32px;
}

.ui-icon-arrowstop-1-s {
  background-position: -224px -32px;
}

.ui-icon-arrowstop-1-w {
  background-position: -240px -32px;
}

.ui-icon-arrowthick-1-n {
  background-position: 1px -48px;
}

.ui-icon-arrowthick-1-ne {
  background-position: -16px -48px;
}

.ui-icon-arrowthick-1-e {
  background-position: -32px -48px;
}

.ui-icon-arrowthick-1-se {
  background-position: -48px -48px;
}

.ui-icon-arrowthick-1-s {
  background-position: -64px -48px;
}

.ui-icon-arrowthick-1-sw {
  background-position: -80px -48px;
}

.ui-icon-arrowthick-1-w {
  background-position: -96px -48px;
}

.ui-icon-arrowthick-1-nw {
  background-position: -112px -48px;
}

.ui-icon-arrowthick-2-n-s {
  background-position: -128px -48px;
}

.ui-icon-arrowthick-2-ne-sw {
  background-position: -144px -48px;
}

.ui-icon-arrowthick-2-e-w {
  background-position: -160px -48px;
}

.ui-icon-arrowthick-2-se-nw {
  background-position: -176px -48px;
}

.ui-icon-arrowthickstop-1-n {
  background-position: -192px -48px;
}

.ui-icon-arrowthickstop-1-e {
  background-position: -208px -48px;
}

.ui-icon-arrowthickstop-1-s {
  background-position: -224px -48px;
}

.ui-icon-arrowthickstop-1-w {
  background-position: -240px -48px;
}

.ui-icon-arrowreturnthick-1-w {
  background-position: 0 -64px;
}

.ui-icon-arrowreturnthick-1-n {
  background-position: -16px -64px;
}

.ui-icon-arrowreturnthick-1-e {
  background-position: -32px -64px;
}

.ui-icon-arrowreturnthick-1-s {
  background-position: -48px -64px;
}

.ui-icon-arrowreturn-1-w {
  background-position: -64px -64px;
}

.ui-icon-arrowreturn-1-n {
  background-position: -80px -64px;
}

.ui-icon-arrowreturn-1-e {
  background-position: -96px -64px;
}

.ui-icon-arrowreturn-1-s {
  background-position: -112px -64px;
}

.ui-icon-arrowrefresh-1-w {
  background-position: -128px -64px;
}

.ui-icon-arrowrefresh-1-n {
  background-position: -144px -64px;
}

.ui-icon-arrowrefresh-1-e {
  background-position: -160px -64px;
}

.ui-icon-arrowrefresh-1-s {
  background-position: -176px -64px;
}

.ui-icon-arrow-4 {
  background-position: 0 -80px;
}

.ui-icon-arrow-4-diag {
  background-position: -16px -80px;
}

.ui-icon-extlink {
  background-position: -32px -80px;
}

.ui-icon-newwin {
  background-position: -48px -80px;
}

.ui-icon-refresh {
  background-position: -64px -80px;
}

.ui-icon-shuffle {
  background-position: -80px -80px;
}

.ui-icon-transfer-e-w {
  background-position: -96px -80px;
}

.ui-icon-transferthick-e-w {
  background-position: -112px -80px;
}

.ui-icon-folder-collapsed {
  background-position: 0 -96px;
}

.ui-icon-folder-open {
  background-position: -16px -96px;
}

.ui-icon-document {
  background-position: -32px -96px;
}

.ui-icon-document-b {
  background-position: -48px -96px;
}

.ui-icon-note {
  background-position: -64px -96px;
}

.ui-icon-mail-closed {
  background-position: -80px -96px;
}

.ui-icon-mail-open {
  background-position: -96px -96px;
}

.ui-icon-suitcase {
  background-position: -112px -96px;
}

.ui-icon-comment {
  background-position: -128px -96px;
}

.ui-icon-person {
  background-position: -144px -96px;
}

.ui-icon-print {
  background-position: -160px -96px;
}

.ui-icon-trash {
  background-position: -176px -96px;
}

.ui-icon-locked {
  background-position: -192px -96px;
}

.ui-icon-unlocked {
  background-position: -208px -96px;
}

.ui-icon-bookmark {
  background-position: -224px -96px;
}

.ui-icon-tag {
  background-position: -240px -96px;
}

.ui-icon-home {
  background-position: 0 -112px;
}

.ui-icon-flag {
  background-position: -16px -112px;
}

.ui-icon-calendar {
  background-position: -32px -112px;
}

.ui-icon-cart {
  background-position: -48px -112px;
}

.ui-icon-pencil {
  background-position: -64px -112px;
}

.ui-icon-clock {
  background-position: -80px -112px;
}

.ui-icon-disk {
  background-position: -96px -112px;
}

.ui-icon-calculator {
  background-position: -112px -112px;
}

.ui-icon-zoomin {
  background-position: -128px -112px;
}

.ui-icon-zoomout {
  background-position: -144px -112px;
}

.ui-icon-search {
  background-position: -160px -112px;
}

.ui-icon-wrench {
  background-position: -176px -112px;
}

.ui-icon-gear {
  background-position: -192px -112px;
}

.ui-icon-heart {
  background-position: -208px -112px;
}

.ui-icon-star {
  background-position: -224px -112px;
}

.ui-icon-link {
  background-position: -240px -112px;
}

.ui-icon-cancel {
  background-position: 0 -128px;
}

.ui-icon-plus {
  background-position: -16px -128px;
}

.ui-icon-plusthick {
  background-position: -32px -128px;
}

.ui-icon-minus {
  background-position: -48px -128px;
}

.ui-icon-minusthick {
  background-position: -64px -128px;
}

.ui-icon-close {
  background-position: -80px -128px;
}

.ui-icon-closethick {
  background-position: -96px -128px;
}

.ui-icon-key {
  background-position: -112px -128px;
}

.ui-icon-lightbulb {
  background-position: -128px -128px;
}

.ui-icon-scissors {
  background-position: -144px -128px;
}

.ui-icon-clipboard {
  background-position: -160px -128px;
}

.ui-icon-copy {
  background-position: -176px -128px;
}

.ui-icon-contact {
  background-position: -192px -128px;
}

.ui-icon-image {
  background-position: -208px -128px;
}

.ui-icon-video {
  background-position: -224px -128px;
}

.ui-icon-script {
  background-position: -240px -128px;
}

.ui-icon-alert {
  background-position: 0 -144px;
}

.ui-icon-info {
  background-position: -16px -144px;
}

.ui-icon-notice {
  background-position: -32px -144px;
}

.ui-icon-help {
  background-position: -48px -144px;
}

.ui-icon-check {
  background-position: -64px -144px;
}

.ui-icon-bullet {
  background-position: -80px -144px;
}

.ui-icon-radio-on {
  background-position: -96px -144px;
}

.ui-icon-radio-off {
  background-position: -112px -144px;
}

.ui-icon-pin-w {
  background-position: -128px -144px;
}

.ui-icon-pin-s {
  background-position: -144px -144px;
}

.ui-icon-play {
  background-position: 0 -160px;
}

.ui-icon-pause {
  background-position: -16px -160px;
}

.ui-icon-seek-next {
  background-position: -32px -160px;
}

.ui-icon-seek-prev {
  background-position: -48px -160px;
}

.ui-icon-seek-end {
  background-position: -64px -160px;
}

.ui-icon-seek-start {
  background-position: -80px -160px;
}

/* ui-icon-seek-first is deprecated, use ui-icon-seek-start instead */
.ui-icon-seek-first {
  background-position: -80px -160px;
}

.ui-icon-stop {
  background-position: -96px -160px;
}

.ui-icon-eject {
  background-position: -112px -160px;
}

.ui-icon-volume-off {
  background-position: -128px -160px;
}

.ui-icon-volume-on {
  background-position: -144px -160px;
}

.ui-icon-power {
  background-position: 0 -176px;
}

.ui-icon-signal-diag {
  background-position: -16px -176px;
}

.ui-icon-signal {
  background-position: -32px -176px;
}

.ui-icon-battery-0 {
  background-position: -48px -176px;
}

.ui-icon-battery-1 {
  background-position: -64px -176px;
}

.ui-icon-battery-2 {
  background-position: -80px -176px;
}

.ui-icon-battery-3 {
  background-position: -96px -176px;
}

.ui-icon-circle-plus {
  background-position: 0 -192px;
}

.ui-icon-circle-minus {
  background-position: -16px -192px;
}

.ui-icon-circle-close {
  background-position: -32px -192px;
}

.ui-icon-circle-triangle-s {
  background-position: -64px -192px;
}

.ui-icon-circle-triangle-n {
  background-position: -96px -192px;
}

.ui-icon-circle-arrow-e {
  background-position: -112px -192px;
}

.ui-icon-circle-arrow-s {
  background-position: -128px -192px;
}

.ui-icon-circle-arrow-w {
  background-position: -144px -192px;
}

.ui-icon-circle-arrow-n {
  background-position: -160px -192px;
}

.ui-icon-circle-zoomin {
  background-position: -176px -192px;
}

.ui-icon-circle-zoomout {
  background-position: -192px -192px;
}

.ui-icon-circle-check {
  background-position: -208px -192px;
}

.ui-icon-circlesmall-plus {
  background-position: 0 -208px;
}

.ui-icon-circlesmall-minus {
  background-position: -16px -208px;
}

.ui-icon-circlesmall-close {
  background-position: -32px -208px;
}

.ui-icon-squaresmall-plus {
  background-position: -48px -208px;
}

.ui-icon-squaresmall-minus {
  background-position: -64px -208px;
}

.ui-icon-squaresmall-close {
  background-position: -80px -208px;
}

.ui-icon-grip-dotted-vertical {
  background-position: 0 -224px;
}

.ui-icon-grip-dotted-horizontal {
  background-position: -16px -224px;
}

.ui-icon-grip-solid-vertical {
  background-position: -32px -224px;
}

.ui-icon-grip-solid-horizontal {
  background-position: -48px -224px;
}

.ui-icon-gripsmall-diagonal-se {
  background-position: -64px -224px;
}

.ui-icon-grip-diagonal-se {
  background-position: -80px -224px;
}

.ui-icon-circle-triangle-w {
  background-image: url("assets/images/global/datepicker-arrow-left.svg");
  background-position: center center;
  background-size: auto 80%;
}

.ui-icon-circle-triangle-e {
  background-image: url("assets/images/global/datepicker-arrow-right.svg");
  background-position: center center;
  background-size: auto 80%;
}

/* Misc visuals
----------------------------------*/
/* Corner radius */
.ui-corner-all,
.ui-corner-top,
.ui-corner-left,
.ui-corner-tl {
  border-top-left-radius: 3px;
}

.ui-corner-all,
.ui-corner-top,
.ui-corner-right,
.ui-corner-tr {
  border-top-right-radius: 3px;
}

.ui-corner-all,
.ui-corner-bottom,
.ui-corner-left,
.ui-corner-bl {
  border-bottom-left-radius: 3px;
}

.ui-corner-all,
.ui-corner-bottom,
.ui-corner-right,
.ui-corner-br {
  border-bottom-right-radius: 3px;
}

/* Overlays */
.ui-widget-overlay {
  background: #aaaaaa;
  opacity: 0.3;
  filter: Alpha(Opacity=30); /* support: IE8 */
}

.ui-widget-shadow {
  -webkit-box-shadow: 0px 0px 5px #666666;
  box-shadow: 0px 0px 5px #666666;
}

h1, h2, h3, h4, h5, h6, p, ul, ol, li, dl, dt, dd {
  margin: 0;
  padding: 0;
}

.title1 {
  font: 700 normal 50px/1.1 "Fieldwork", Helvetica, Arial, sans-serif;
  color: #50c11e;
}
@media screen and (max-width: 1200px) {
  .title1 {
    font-size: 48px;
  }
}
@media screen and (max-width: 1023px) {
  .title1 {
    font-size: 44px;
  }
}
@media screen and (max-width: 767px) {
  .title1 {
    font-size: 38px;
  }
}
.title1 + p,
.title1 + .text,
.title1 + h4 {
  margin-top: 1em;
}

.title2 {
  font: 700 normal 40px/1.1 "Fieldwork", Helvetica, Arial, sans-serif;
  color: #50c11e;
}
@media screen and (max-width: 1200px) {
  .title2 {
    font-size: 38px;
  }
}
@media screen and (max-width: 1023px) {
  .title2 {
    font-size: 34px;
  }
}
@media screen and (max-width: 767px) {
  .title2 {
    font-size: 30px;
  }
}
.title2 + p,
.title2 + .text {
  margin-top: 0.5em;
}

.title3 {
  font: 700 normal 24px/1.3 "Fieldwork", Helvetica, Arial, sans-serif;
  color: #555;
}
@media screen and (max-width: 1023px) {
  .title3 {
    font-size: 21px;
  }
}
@media screen and (max-width: 767px) {
  .title3 {
    font-size: 20px;
  }
}
.title3 + p,
.title3 + .text {
  margin-top: 0.3em;
}

.title4 {
  font: 700 normal 20px/1.4 "Fieldwork", Helvetica, Arial, sans-serif;
  letter-spacing: 0.02em;
  color: #333;
}
@media screen and (max-width: 1200px) {
  .title4 {
    font-size: 19px;
  }
}
@media screen and (max-width: 1023px) {
  .title4 {
    font-size: 18px;
  }
}
@media screen and (max-width: 767px) {
  .title4 {
    font-size: 17px;
  }
}
.title4 + p,
.title4 + .text {
  margin-top: 0.3em;
}

p,
.text {
  font: 400 normal 18px/1.5 "Fieldwork", Helvetica, Arial, sans-serif;
  color: #555;
}
@media screen and (max-width: 767px) {
  p,
  .text {
    font-size: 17px;
  }
}
p a,
.text a {
  color: inherit;
  text-decoration: none;
  transition: color 0.3s;
  border-bottom: 1px solid currentColor;
}
p a:hover,
.text a:hover {
  color: #50c11e;
}
p + h4,
.text + h4 {
  margin-top: 1.5em;
}

ol.text,
ul.text {
  margin-left: 1.2em;
}

.meta {
  font: 500 normal 15px/1.6 "Fieldwork", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.01em;
}

address {
  font-style: normal;
}

.tracking-fix {
  letter-spacing: -0.08em;
}

select::-ms-expand {
  display: none;
}

.button {
  font: 700 normal 18px/1.2 "Fieldwork", Helvetica, Arial, sans-serif;
  letter-spacing: 0.02em;
  text-decoration: none;
  color: #fff;
  background-color: #50c11e;
  display: inline-block;
  padding: 0.9em 2em;
  border: 0;
  transition: background-color 0.3s, color 0.3s;
  margin-top: 1.5em;
  text-align: center;
  border-radius: 30px;
}
.button:hover {
  background-color: #3e9517;
  color: #fff;
  cursor: pointer;
  outline: 0;
}

.text-button {
  font: 700 normal 18px/1.2 "Fieldwork", Helvetica, Arial, sans-serif;
  letter-spacing: 0.02em;
  text-decoration: none;
  margin-top: 1em;
  padding-right: 0.6em;
  display: inline-block;
  color: #50c11e;
  position: relative;
  transition: color 0.3s;
}
.text-button:after {
  content: "";
  position: absolute;
  right: 0;
  top: 45%;
  border-top: currentColor solid 2px;
  border-right: currentColor solid 2px;
  width: 0.25em;
  height: 0.25em;
  transform-origin: left center;
  transform: rotate(45deg) translate(-50%, -50%);
  transition: border-color 0.3s;
}
.text-button:hover {
  color: #3e9517;
}

.header {
  position: fixed;
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 2000;
  height: 120px;
  padding: 0 30px;
  background-color: #111;
  transition: width 0.4s, background-color 0.4s, transform 0.4s, box-shadow 0.4s;
}
@media screen and (max-width: 1023px) {
  .header {
    height: 90px;
    padding: 0 20px 0 25px;
  }
}
.header.open .header-logo .aaron-logo-white {
  fill: #333;
}
.header.open #menu-main .menu-item {
  color: #333;
}
.header.open #menu-main .menu-item:after {
  border-color: #333;
}
.header.open .header-shop-item {
  color: #333;
}
.header.open .header-shop-item svg {
  fill: #333;
}
.header-logo {
  position: relative;
  z-index: 101;
  margin-right: auto;
}
.header-logo img {
  display: block;
  height: 95px;
  width: auto;
}
@media screen and (max-width: 1023px) {
  .header-logo img {
    height: 70px;
  }
}
.header-logo img .aaron-logo-white {
  transition: fill 0.4s;
}
@media screen and (max-width: 767px) {
  .header > .header-nav-utility-wrap {
    display: none !important;
    opacity: 0 !important;
    visibility: hidden !important;
    pointer-events: none !important;
  }
}
.header-nav {
  position: fixed;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-content: center;
  height: 100%;
  right: 0;
  top: 0;
  width: 55%;
  z-index: 100;
  background-color: #000;
  padding: 0 0 0 8%;
  transform: translateX(100%);
  visibility: hidden;
  transition: opacity 0.5s, visibility 0.5s, transform 0.5s;
}
@media screen and (max-width: 1680px) {
  .header-nav {
    padding-left: 7%;
  }
}
@media screen and (max-width: 1200px) {
  .header-nav {
    width: 80%;
  }
}
@media screen and (max-width: 1023px) {
  .header-nav {
    padding-left: 10%;
  }
}
@media screen and (max-width: 767px) {
  .header-nav {
    width: 100%;
    padding: 110px 30px 0;
    align-content: flex-start;
  }
}
.header-nav.open {
  visibility: visible;
  transform: translate(0);
}
.header-nav > .header-nav-utility-wrap {
  display: none;
}
@media screen and (max-width: 767px) {
  .header-nav > .header-nav-utility-wrap {
    display: flex;
  }
}
.header-nav .menu-main-container {
  width: 37%;
}
@media screen and (max-width: 767px) {
  .header-nav .menu-main-container {
    margin-bottom: 15px;
  }
}
.header-nav .menu-main-container ul li {
  font: 400 normal 48px/1 "Fieldwork", Helvetica, Arial, sans-serif;
}
@media screen and (max-width: 1680px) {
  .header-nav .menu-main-container ul li {
    font-size: 2.5vw;
  }
}
@media screen and (max-width: 1500px) {
  .header-nav .menu-main-container ul li {
    font-size: 34px;
  }
}
@media screen and (max-width: 1200px) {
  .header-nav .menu-main-container ul li {
    font-size: 3.5vw;
  }
}
@media screen and (max-width: 1023px) {
  .header-nav .menu-main-container ul li {
    font-size: 30px;
  }
}
@media screen and (max-width: 480px) {
  .header-nav .menu-main-container ul li {
    font-size: 26px;
  }
}
.header-nav .menu-services-container {
  width: 63%;
}
.header-nav .menu-services-container ul li {
  font: 700 normal 34px/1 "Fieldwork", Helvetica, Arial, sans-serif;
}
@media screen and (max-width: 1680px) {
  .header-nav .menu-services-container ul li {
    font-size: 2vw;
  }
}
@media screen and (max-width: 1500px) {
  .header-nav .menu-services-container ul li {
    font-size: 28px;
  }
}
@media screen and (max-width: 1200px) {
  .header-nav .menu-services-container ul li {
    font-size: 3vw;
  }
}
@media screen and (max-width: 1023px) {
  .header-nav .menu-services-container ul li {
    font-size: 24px;
  }
}
@media screen and (max-width: 480px) {
  .header-nav .menu-services-container ul li {
    font-size: 20px;
  }
}
.header-nav #menu-main,
.header-nav #menu-services {
  height: 100%;
}
@media screen and (max-width: 767px) {
  .header-nav .menu-main-container,
  .header-nav .menu-services-container {
    width: 100%;
  }
}
.header-nav .menu-main-container ul,
.header-nav .menu-services-container ul {
  display: flex;
  flex-flow: column nowrap;
}
.header-nav .menu-main-container ul li,
.header-nav .menu-services-container ul li {
  list-style-type: none;
  position: relative;
}
.header-nav .menu-main-container ul li a,
.header-nav .menu-services-container ul li a {
  display: block;
  padding: 15px 0;
  position: relative;
  text-decoration: none;
  transition: color 0.2s;
  position: relative;
  z-index: 2;
  color: #fff;
}
@media screen and (max-width: 767px) {
  .header-nav .menu-main-container ul li a,
  .header-nav .menu-services-container ul li a {
    padding: 0.4em 0;
  }
}
.header-nav .menu-main-container ul li a:hover,
.header-nav .menu-services-container ul li a:hover {
  color: #50c11e;
}
.header-nav-utility {
  display: flex;
  flex-flow: row nowrap;
}
.header-nav-utility-wrap {
  display: flex;
  flex-flow: row nowrap;
  margin: 0 30px 0 auto;
}
@media screen and (max-width: 767px) {
  .header-nav-utility-wrap {
    margin: 20px 0 0 -14px;
  }
}
.header-nav-utility li {
  list-style-type: none;
}
.header-nav-utility li a {
  display: block;
  padding: 10px 15px;
  transition: opacity 0.2s;
}
.header-nav-utility li a:hover {
  opacity: 0.7;
}
.header-nav-utility li img {
  display: block;
  height: 20px;
  width: auto;
}
.header-nav-utility + .header-nav-utility {
  border-left: 1px solid #ccc;
  margin-left: 20px;
  padding-left: 20px;
}
.header-nav-utility-cart {
  position: relative;
}
.header-nav-utility-cart-count {
  position: absolute;
  top: 0;
  left: calc(100% - 1.8em);
  background-color: #50c11e;
  color: #fff;
  text-decoration: none;
  border-radius: 1em;
  min-width: 1.5em;
  padding: 0 0.5em;
  height: 1.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  font: 700 normal 12px/1 "Fieldwork", Helvetica, Arial, sans-serif;
}
.header-nav-button {
  border: 0;
  border-radius: 0;
  box-sizing: border-box;
  box-shadow: none;
  appearance: none;
  -webkit-appearance: none;
  width: 50px;
  height: 50px;
  padding: 0;
  position: relative;
  background-color: transparent !important;
  order: 1;
  z-index: 101;
  outline: 0;
}
.header-nav-button span {
  display: block;
  width: 30px;
  height: 2px;
  background-color: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  opacity: 1;
  transition: transform 0.4s, opacity 0.4s, width 0.4s, background-color 0.4s;
}
.header-nav-button span.top {
  transform: translate(-50%, -7px) rotate(0deg);
  transform-origin: left top;
}
.header-nav-button span.middle {
  transform: translate(-50%, 0) rotate(0deg);
}
.header-nav-button span.bottom {
  transform-origin: left bottom;
  transform: translate(-50%, 7px) rotate(0deg);
}
.header-nav-button.open span {
  background: #fff;
}
.header-nav-button.open span.top {
  transform: translate(-35%, -8px) rotate(45deg);
}
.header-nav-button.open span.middle {
  opacity: 0;
}
.header-nav-button.open span.bottom {
  transform: translate(-35%, 12px) rotate(-45deg);
}
.header-nav-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(17, 17, 17, 0.5);
  z-index: 100;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.3s, opacity 0.3s;
}
.header-nav-overlay.open {
  visibility: visible;
  opacity: 1;
}

.footer {
  background: #111;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 60px 0 40px;
  color: #fff;
  font: 300 normal 16px/1.4 "Fieldwork", Helvetica, Arial, sans-serif;
  text-align: center;
  clear: both;
}
@media screen and (max-width: 767px) {
  .footer {
    padding: 30px 0;
  }
}
.footer address {
  line-height: 1.2em;
}
.footer a {
  color: inherit;
  text-decoration: none;
  display: inline-block;
  border-bottom: 1px solid transparent;
}
.footer-copyright {
  margin: 10px 0 15px;
  padding: 0;
  display: flex;
  flex-flow: row nowrap;
}
@media screen and (max-width: 480px) {
  .footer-copyright {
    flex-direction: column;
  }
}
.footer-copyright li {
  list-style-type: none;
  margin-right: 15px;
  margin-top: 0.4em;
  padding-right: 15px;
  border-right: currentColor solid 1px;
}
@media screen and (max-width: 480px) {
  .footer-copyright li {
    margin-right: 0;
    padding-right: 0;
    border-right: 0;
  }
}
.footer-copyright li:last-child {
  margin-right: 0;
  padding-right: 0;
  border-right: 0;
}
.footer-copyright a:hover {
  border-bottom-color: currentColor;
}
.footer-logo svg {
  height: 120px;
  width: auto;
  margin-bottom: 20px;
  fill: #fff;
}
@media screen and (max-width: 767px) {
  .footer-logo svg {
    height: 80px;
  }
}
.footer-social {
  display: flex;
}
.footer-social li {
  list-style-type: none;
}
.footer-social a {
  display: block;
  width: 40px;
  height: 40px;
  position: relative;
}
.footer-social a svg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  fill: #fff;
  height: 50%;
  width: auto;
}
.footer-social a svg,
.footer-social a path,
.footer-social a circle {
  transition: fill 0.3s;
}
.footer-social a path,
.footer-social a circle {
  fill: #fff;
}
.footer-social a:hover svg,
.footer-social a:hover path,
.footer-social a:hover circle {
  fill: #999;
}

.hero {
  width: calc(100% - 40px);
  margin: 20px 0 100px 20px;
  overflow: hidden;
  border-radius: 10px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  position: relative;
}
@media screen and (max-width: 1023px) {
  .hero {
    margin-bottom: 60px;
  }
}
@media screen and (max-width: 767px) {
  .hero {
    width: calc(100% - 30px);
    margin: 15px 0 40px 15px;
    border-radius: 5px;
  }
}
.hero-content {
  width: 45%;
  padding: 10vw 0 8vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f1f1f1;
}
@media screen and (max-width: 1500px) {
  .hero-content {
    width: 45%;
    padding: 140px 0 60px;
  }
}
@media screen and (max-width: 1200px) {
  .hero-content {
    padding: 140px 0 60px;
    width: 50%;
  }
}
@media screen and (max-width: 1023px) {
  .hero-content {
    width: 100%;
    padding: 40px 0;
  }
}
.hero-content-inner {
  max-width: 700px;
  padding: 0 80px;
}
@media screen and (max-width: 1200px) {
  .hero-content-inner {
    padding: 0 60px;
  }
}
@media screen and (max-width: 1023px) {
  .hero-content-inner {
    padding: 0 40px;
    margin: 0 auto;
    max-width: 600px;
  }
}
@media screen and (max-width: 767px) {
  .hero-content-inner {
    max-width: 500px;
    padding: 0 30px;
  }
}
.hero-media {
  width: 55%;
}
@media screen and (max-width: 1500px) {
  .hero-media {
    width: 55%;
  }
}
@media screen and (max-width: 1200px) {
  .hero-media {
    width: 50%;
  }
}
@media screen and (max-width: 1023px) {
  .hero-media {
    width: 100%;
    order: -1;
  }
}
@media screen and (max-width: 767px) {
  .hero-media {
    top: 40px;
  }
}
.hero-slider {
  height: 100%;
}
.hero-slider-item {
  background-color: #aaa;
  position: relative;
}
@media screen and (max-width: 1023px) {
  .hero-slider-item {
    height: 50vw;
    min-height: 400px;
  }
}
@media screen and (max-width: 767px) {
  .hero-slider-item {
    height: 50vw;
    min-height: 250px;
  }
}
.hero-slider-item img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.hero-slider .slick-list {
  height: 100%;
}
.hero-slider .slick-track {
  height: 100%;
}
.hero-slider .slick-prev {
  display: none !important;
}
.hero-slider .slick-next {
  border: 0;
  border-radius: 0;
  box-sizing: border-box;
  box-shadow: none;
  appearance: none;
  -webkit-appearance: none;
  width: 50px;
  height: 50px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #fff url("assets/images/global/slick-arrow-right.svg") 55% center/auto 35% no-repeat;
  border-radius: 50%;
  text-indent: -9999px;
  box-shadow: 0 5px 20px -5px rgba(0, 0, 0, 0.2);
  outline: 0;
}
@media screen and (max-width: 1023px) {
  .hero-slider .slick-next {
    display: none !important;
  }
}
.hero-slider .slick-dots {
  display: none !important;
}
.hero-title1 {
  font: 700 normal 60px/1 "Fieldwork", Helvetica, Arial, sans-serif;
  letter-spacing: -0.02em;
  color: #50c11e;
  position: relative;
  z-index: 2;
  margin-bottom: 20px;
}
@media screen and (max-width: 1680px) {
  .hero-title1 {
    font-size: 50px;
  }
}
@media screen and (max-width: 1200px) {
  .hero-title1 {
    font-size: 46px;
  }
}
@media screen and (max-width: 1023px) {
  .hero-title1 {
    font-size: 42px;
  }
}
@media screen and (max-width: 767px) {
  .hero-title1 {
    font-size: 34px;
  }
}
.hero-icon {
  width: 80px;
  height: 80px;
  background-color: #50c11e;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  position: relative;
  z-index: 3;
  margin-bottom: 20px;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.5);
}
@media screen and (max-width: 767px) {
  .hero-icon {
    height: 60px;
    width: 60px;
    margin-bottom: 10px;
  }
}
@media screen and (max-width: 480px) {
  .hero-icon {
    height: 50px;
    width: 50px;
  }
}
.hero-icon svg {
  width: auto;
  height: auto;
  max-width: 50%;
  max-height: 80%;
}
.hero-arrow {
  border: 0;
  border-radius: 0;
  box-sizing: border-box;
  box-shadow: none;
  appearance: none;
  -webkit-appearance: none;
  position: absolute;
  z-index: 3;
  left: 50%;
  bottom: 0;
  width: 50px;
  height: 50px;
  background: transparent;
  transform: translateX(-50%);
}
.hero-arrow:after {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -20px) rotate(-45deg);
  border-left: #fff solid 3px;
  border-bottom: #fff solid 3px;
  width: 20px;
  height: 20px;
  transition: transform 0.2s;
}
.hero-arrow:hover {
  outline: 0;
  cursor: pointer;
}
.hero-arrow:hover:after {
  transform: translate(-50%, -15px) rotate(-45deg);
}

.intro {
  padding: 65px 140px 80px;
  margin: 0 auto;
  width: calc(100% - 80px);
  max-width: 840px;
  position: relative;
}
@media screen and (max-width: 1023px) {
  .intro {
    max-width: 700px;
    padding: 60px 100px 70px;
  }
}
@media screen and (max-width: 767px) {
  .intro {
    padding: 60px;
  }
}
@media screen and (max-width: 480px) {
  .intro {
    width: calc(100% - 30px);
    padding: 50px 30px;
  }
}
.intro.center {
  text-align: center;
}
.intro.fill {
  margin: 100px auto;
  border-radius: 20px;
  background-color: #f1f1f1;
}
@media screen and (max-width: 767px) {
  .intro.fill {
    border-radius: 10px;
    margin: 60px auto 40px;
    border-radius: 5px;
  }
}
.intro-icon {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
  width: 80px;
  height: 80px;
  background-color: #50c11e;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .intro-icon {
    height: 60px;
    width: 60px;
  }
}
.intro-icon img {
  width: auto;
  height: auto;
  max-width: 50%;
  max-height: 80%;
}

.grid {
  padding: 0 80px 40px;
}
@media screen and (max-width: 1200px) {
  .grid {
    padding: 40px 30px 0;
  }
}
@media screen and (max-width: 767px) {
  .grid {
    padding: 30px 20px 0;
  }
}
.grid-header {
  padding-bottom: 80px;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .grid-header {
    padding-bottom: 60px;
  }
}
.grid-items {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  margin: 0 auto;
  max-width: 1200px;
}
.grid-items .grid-item {
  width: calc(33.3333333333% - (60px / 3));
  margin-right: 30px;
}
@media screen and (max-width: 1023px) {
  .grid-items .grid-item {
    width: calc(50% - (30px / 2));
  }
}
@media screen and (max-width: 767px) {
  .grid-items .grid-item {
    width: 100%;
    margin-right: 0;
  }
}
.grid-items .grid-item:nth-child(3n) {
  margin-right: 0;
}
@media screen and (max-width: 1023px) {
  .grid-items .grid-item:nth-child(3n) {
    margin-right: 30px;
  }
}
@media screen and (max-width: 767px) {
  .grid-items .grid-item:nth-child(3n) {
    margin-right: 0;
  }
}
@media screen and (max-width: 1023px) {
  .grid-items .grid-item:nth-child(2n) {
    margin-right: 0;
  }
}
.grid-item {
  margin-bottom: 80px;
  position: relative;
}
@media screen and (max-width: 767px) {
  .grid-item {
    max-width: 400px;
    margin-bottom: 70px;
  }
}
@media screen and (max-width: 480px) {
  .grid-item {
    margin-right: 0;
  }
}
.grid-item > img {
  width: 100%;
  height: auto;
  display: block;
  margin-bottom: 20px;
}
.grid-item-img {
  display: block;
  height: 300px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 20px;
  border-radius: 5px;
}
@media screen and (max-width: 480px) {
  .grid-item-img {
    height: 200px;
  }
}
.grid-item-content {
  padding: 0 10px;
}
.grid-item-content h2 + h3 {
  border-top: 0;
  padding-top: 0;
}
.grid-item-icon {
  height: 80px;
  margin: 0 auto 15px 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
@media screen and (max-width: 1023px) {
  .grid-item-icon {
    height: 70px;
  }
}
.grid-item-icon svg,
.grid-item-icon img {
  width: auto;
  height: 100%;
}

.tiles-items {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 30px;
}
@media screen and (max-width: 1680px) {
  .tiles-items {
    max-width: 1200px;
  }
}
@media screen and (max-width: 1200px) {
  .tiles-items {
    max-width: 760px;
  }
}
@media screen and (max-width: 767px) {
  .tiles-items {
    max-width: 500px;
    padding-top: 40px;
  }
}
.tiles-item {
  width: calc(25% - (180px / 4));
  margin-right: 60px;
  margin-bottom: 60px;
}
@media screen and (max-width: 1200px) {
  .tiles-item {
    width: calc(50% - (60px / 2));
  }
}
@media screen and (max-width: 767px) {
  .tiles-item {
    width: 100%;
    margin-right: 0;
  }
}
.tiles-item:nth-child(4n) {
  margin-right: 0;
}
@media screen and (max-width: 1200px) {
  .tiles-item:nth-child(2n) {
    margin-right: 0;
  }
}
.tiles-item-icon {
  display: block;
  margin-bottom: 20px;
}
.tiles-item-icon img {
  display: block;
  height: 50px;
  width: auto;
}

.diptych {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1600px;
  padding: 0 30px 100px;
}
@media screen and (max-width: 1023px) {
  .diptych {
    padding: 0 20px 60px;
  }
}
@media screen and (max-width: 767px) {
  .diptych {
    padding-left: 0;
    padding-right: 0;
  }
}
.diptych-content {
  width: 45%;
  padding: 120px 100px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
}
@media screen and (max-width: 1200px) {
  .diptych-content {
    padding: 60px 80px;
  }
}
@media screen and (max-width: 1023px) {
  .diptych-content {
    padding: 40px;
  }
}
@media screen and (max-width: 767px) {
  .diptych-content {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    padding: 30px;
  }
}
.diptych-content > * {
  width: 100%;
}
.diptych-content .button,
.diptych-content .text-button {
  width: auto;
}
.diptych-content.center {
  text-align: center;
}
.diptych-content-icon {
  height: 80px;
  margin: 0 auto 15px 2px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
@media screen and (max-width: 1023px) {
  .diptych-content-icon {
    height: 70px;
  }
}
.diptych-content-icon svg,
.diptych-content-icon img {
  width: auto;
  height: 100%;
}
.diptych-image {
  width: 55%;
  min-height: 550px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;
}
@media screen and (max-width: 1023px) {
  .diptych-image {
    min-height: 40vw;
  }
}
@media screen and (max-width: 767px) {
  .diptych-image {
    width: calc(100% - 30px);
    height: 50vw;
    min-height: 250px;
    max-width: 600px;
    margin: 0 auto;
    order: -1;
    border-radius: 5px;
  }
}
.diptych:first-child {
  padding-top: 40px;
}
.diptych-group .diptych:nth-child(even) .diptych-content {
  order: -1;
}
.gallery {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 0 15px;
}
.gallery-item {
  margin-bottom: 15px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
}
@media screen and (max-width: 767px) {
  .gallery-item {
    width: 100% !important;
    height: 68vw !important;
    border-radius: 5px;
  }
}
.gallery-item:nth-child(5n+1), .gallery-item:nth-child(5n+2) {
  width: calc(50% - (15px / 2));
  height: 34vw;
}
.gallery-item:nth-child(5n+3), .gallery-item:nth-child(5n+4), .gallery-item:nth-child(5n+5) {
  width: calc(33.3333333333% - (30px / 3));
  height: 23vw;
}

.large-image {
  display: block;
  width: calc(100% - 80px);
  max-width: 1400px;
  margin: 0 auto 100px;
  height: auto;
}
@media screen and (max-width: 480px) {
  .large-image {
    width: calc(100% - 40px);
  }
}

.details-items {
  width: calc(100% - 80px);
  max-width: 1000px;
  margin: 0 auto 80px;
}
@media screen and (max-width: 480px) {
  .details-items {
    width: calc(100% - 40px);
  }
}
.details-item {
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
  border-radius: 10px;
  border: #f1f1f1 solid 2px;
  margin-bottom: 30px;
}
.details-item-details {
  width: 62% !important;
  padding: 25px 50px 30px 35px !important;
}
@media screen and (max-width: 767px) {
  .details-item-details {
    width: 100% !important;
    padding: 20px 25px 25px !important;
  }
}
.details-item-details h3 {
  font: 700 normal 24px/1.3 "Fieldwork", Helvetica, Arial, sans-serif;
  color: #555;
}
@media screen and (max-width: 1023px) {
  .details-item-details h3 {
    font-size: 21px;
  }
}
@media screen and (max-width: 767px) {
  .details-item-details h3 {
    font-size: 20px;
  }
}
.details-item-details h3 + p {
  margin-top: 0.5em;
}
.details-item-details p + p {
  margin-top: 0.5em !important;
}
.details-item-prices {
  width: 38%;
  padding: 23px 35px;
  background-color: #f1f1f1;
}
@media screen and (max-width: 767px) {
  .details-item-prices {
    width: 100%;
    margin-left: 0;
    padding: 20px 25px;
  }
}
.details-item-prices p {
  font-weight: 700;
}
.details-item-prices p + p {
  margin-top: 0.5em;
}
.details-item-prices ul {
  margin-left: 0.8em;
  font: 400 normal 18px/1.5 "Fieldwork", Helvetica, Arial, sans-serif;
  color: #555;
}
@media screen and (max-width: 767px) {
  .details-item-prices ul {
    font-size: 17px;
  }
}

.wp_rss_retriever .wp_rss_retriever_list {
  margin: 0 auto !important;
}
.wp_rss_retriever_image {
  order: -1;
  width: auto !important;
  height: 250px !important;
  float: none !important;
  margin-right: 0 !important;
  margin-bottom: 20px !important;
}
@media screen and (max-width: 480px) {
  .wp_rss_retriever_image {
    height: 200px !important;
  }
}
.wp_rss_retriever_image img {
  width: 100% !important;
  max-width: 100% !important;
  min-height: 0 !important;
  min-width: 0 !important;
}
.wp_rss_retriever_title,
.wp_rss_retriever h3 {
  font: 700 normal 24px/1.3 "Fieldwork", Helvetica, Arial, sans-serif;
  color: #555;
  margin-bottom: 0 !important;
}
@media screen and (max-width: 1023px) {
  .wp_rss_retriever_title,
  .wp_rss_retriever h3 {
    font-size: 21px;
  }
}
@media screen and (max-width: 767px) {
  .wp_rss_retriever_title,
  .wp_rss_retriever h3 {
    font-size: 20px;
  }
}
.wp_rss_retriever_item {
  display: none;
}
.wp_rss_retriever_item_wrapper {
  display: flex;
  flex-flow: column nowrap;
  text-decoration: none;
}
.wp_rss_retriever_credits {
  display: none;
}

.stu .wp_rss_retriever {
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
}
.stu .wp_rss_retriever:before, .stu .wp_rss_retriever:after {
  content: "";
  position: absolute;
  width: 80px;
  height: 100%;
  top: 0;
  z-index: 2;
}
@media screen and (max-width: 1023px) {
  .stu .wp_rss_retriever:before, .stu .wp_rss_retriever:after {
    display: none;
  }
}
.stu .wp_rss_retriever:before {
  background-color: #f0f0f0;
  left: 0;
}
.stu .wp_rss_retriever:after {
  background-color: #f0f0f0;
  right: 0;
}
.stu .wp_rss_retriever:hover {
  cursor: grab;
}
.stu .wp_rss_retriever:active {
  cursor: grabbing;
}
.stu .wp_rss_retriever_item {
  margin: 0 10px;
}
.stu .wp_rss_retriever .slick-arrow {
  position: absolute;
  z-index: 3;
  width: 80px;
  height: 100%;
  top: 0;
  text-indent: -9999px;
  border: 0;
  border-radius: 0;
  box-sizing: border-box;
  box-shadow: none;
  appearance: none;
  -webkit-appearance: none;
  background-color: transparent;
  background-position: center 30%;
  background-size: 20% auto;
  background-repeat: no-repeat;
  opacity: 0.5;
  transition: opacity 0.3s;
}
.stu .wp_rss_retriever .slick-arrow:hover {
  outline: 0;
  cursor: pointer;
  opacity: 1;
}
.stu .wp_rss_retriever .slick-prev {
  left: 0;
  background-image: url("assets/images/global/slick-arrow-left.svg");
}
.stu .wp_rss_retriever .slick-next {
  right: 0;
  background-image: url("assets/images/global/slick-arrow-right.svg");
}

.grid .wp_rss_retriever_list {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 0 auto;
  max-width: 1200px;
}
.grid .wp_rss_retriever_item {
  width: calc(33.3333333333% - (60px / 3));
  margin-right: 30px;
  margin-bottom: 60px;
  text-align: center;
}
@media screen and (max-width: 1023px) {
  .grid .wp_rss_retriever_item {
    width: calc(50% - (30px / 2));
  }
}
@media screen and (max-width: 767px) {
  .grid .wp_rss_retriever_item {
    width: 100%;
    margin-right: 0;
    max-width: 400px;
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 480px) {
  .grid .wp_rss_retriever_item {
    margin-right: 0;
  }
}
.grid .wp_rss_retriever_item:nth-child(3n) {
  margin-right: 0;
}
@media screen and (max-width: 1023px) {
  .grid .wp_rss_retriever_item:nth-child(3n) {
    margin-right: 30px;
  }
}
@media screen and (max-width: 767px) {
  .grid .wp_rss_retriever_item:nth-child(3n) {
    margin-right: 0;
  }
}
@media screen and (max-width: 1023px) {
  .grid .wp_rss_retriever_item:nth-child(2n) {
    margin-right: 0;
  }
}

.google-map iframe {
  width: 100%;
  height: 75vw;
  max-height: 450px;
}

.alert {
  padding: 60px 140px;
  margin: 80px auto;
  width: calc(100% - 80px);
  max-width: 840px;
  position: relative;
  background: rgba(255, 0, 0, 0.1);
  border: #ff0000 solid 2px;
  border-radius: 5px;
  text-align: center;
}
@media screen and (max-width: 1023px) {
  .alert {
    max-width: 700px;
    padding: 80px 100px;
  }
}
@media screen and (max-width: 767px) {
  .alert {
    padding: 60px;
    margin: 60px auto;
  }
}
@media screen and (max-width: 480px) {
  .alert {
    width: calc(100% - 40px);
    padding: 40px 25px;
  }
}
.alert:before {
  content: "i";
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 56px;
  padding-top: 4px;
  background-color: #ff0000;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font: 400 normal 30px/1 "Fieldwork", Helvetica, Arial, sans-serif;
  color: #fff;
}
@media screen and (max-width: 767px) {
  .alert:before {
    width: 50px;
    height: 46px;
    font-size: 25px;
  }
}
.alert .title1,
.alert .title2,
.alert .title3,
.alert .title4 {
  color: #ff0000;
}
.alert + .intro {
  padding-top: 0 !important;
}

.home-services-intro {
  text-align: center;
  padding: 60px 40px 20px;
}
@media screen and (max-width: 767px) {
  .home-services-intro {
    padding: 40px 20px 0;
  }
}
.home-services-items {
  display: flex;
  flex-flow: row wrap;
  margin: 30px 0;
  padding-top: 30px;
  border-top: #eee solid 2px;
  text-align: left;
}
@media screen and (max-width: 1023px) {
  .home-services-items {
    margin: 20px 0 0;
  }
}
.home-services-items li {
  list-style-type: none;
  margin-bottom: 10px;
  width: 50%;
  font: 700 normal 14px/1.2 "Fieldwork", Helvetica, Arial, sans-serif;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: #555;
  position: relative;
  padding-left: 1em;
  padding-right: 1em;
}
@media screen and (max-width: 1023px) {
  .home-services-items li {
    font-size: 13px;
  }
}
@media screen and (max-width: 767px) {
  .home-services-items li {
    font-size: 12px;
  }
}
.home-services-items li:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0.3em;
  margin-right: 5px;
  width: 6px;
  height: 6px;
  background: #50c11e;
  border-radius: 50%;
}
.home-instagram {
  padding: 100px 40px;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .home-instagram {
    padding: 40px 20px;
  }
}
@media screen and (max-width: 480px) {
  .home-instagram {
    padding: 0 10px;
  }
}
.home-instagram svg {
  display: block;
  width: auto;
  height: 40px;
  margin: 0 auto 10px;
}
@media screen and (max-width: 767px) {
  .home-instagram svg {
    height: 30px;
  }
}
.home-instagram-title {
  font: 700 normal 40px/1 "Fieldwork", Helvetica, Arial, sans-serif;
  color: #50c11e;
  margin-bottom: 0.7em;
}
@media screen and (max-width: 767px) {
  .home-instagram-title {
    font-size: 7vw;
  }
}
.home-instagram-title a {
  color: inherit;
  text-decoration: none;
  display: inline-block;
}
.home-instagram-title a:hover {
  color: #50c11e;
}

#sb_instagram .sbi_photo img {
  height: 100% !important;
  object-fit: cover;
}

.sbi_photo_wrap {
  border-radius: 5px;
  overflow: hidden;
}

.article {
  padding: 60px 0 40px;
  min-height: calc(100vh - 230px - 345px);
}
@media screen and (max-width: 767px) {
  .article {
    min-height: calc(100vh - 70px - 285px);
  }
}
@media screen and (max-width: 480px) {
  .article {
    padding: 40px 0 20px;
    min-height: calc(100vh - 70px - 330px);
  }
}
.article-content {
  width: calc(100% - 80px);
  max-width: 1000px;
  margin: 0 auto;
  padding-bottom: 100px;
}
@media screen and (max-width: 767px) {
  .article-content {
    width: calc(100% - 60px);
  }
}
.article-content h1 {
  font: 700 normal 50px/1.1 "Fieldwork", Helvetica, Arial, sans-serif;
  color: #50c11e;
  margin-bottom: 0.5em;
}
@media screen and (max-width: 1200px) {
  .article-content h1 {
    font-size: 48px;
  }
}
@media screen and (max-width: 1023px) {
  .article-content h1 {
    font-size: 44px;
  }
}
@media screen and (max-width: 767px) {
  .article-content h1 {
    font-size: 38px;
  }
}
.article-content h2 {
  font: 700 normal 40px/1.1 "Fieldwork", Helvetica, Arial, sans-serif;
  color: #50c11e;
}
@media screen and (max-width: 1200px) {
  .article-content h2 {
    font-size: 38px;
  }
}
@media screen and (max-width: 1023px) {
  .article-content h2 {
    font-size: 34px;
  }
}
@media screen and (max-width: 767px) {
  .article-content h2 {
    font-size: 30px;
  }
}
.article-content h3 {
  font: 700 normal 24px/1.3 "Fieldwork", Helvetica, Arial, sans-serif;
  color: #555;
}
@media screen and (max-width: 1023px) {
  .article-content h3 {
    font-size: 21px;
  }
}
@media screen and (max-width: 767px) {
  .article-content h3 {
    font-size: 20px;
  }
}
.article-content h4 {
  font: 700 normal 20px/1.4 "Fieldwork", Helvetica, Arial, sans-serif;
  letter-spacing: 0.02em;
  color: #333;
}
@media screen and (max-width: 1200px) {
  .article-content h4 {
    font-size: 19px;
  }
}
@media screen and (max-width: 1023px) {
  .article-content h4 {
    font-size: 18px;
  }
}
@media screen and (max-width: 767px) {
  .article-content h4 {
    font-size: 17px;
  }
}
.article-content h1 + h1,
.article-content h1 + h2,
.article-content h1 + h3,
.article-content h1 + h4,
.article-content h2 + h1,
.article-content h2 + h2,
.article-content h2 + h3,
.article-content h2 + h4,
.article-content h3 + h1,
.article-content h3 + h2,
.article-content h3 + h3,
.article-content h3 + h4,
.article-content h4 + h1,
.article-content h4 + h2,
.article-content h4 + h3,
.article-content h4 + h4 {
  margin-top: 1em;
}
.article-content h1 + p,
.article-content h1 + ul,
.article-content h1 + ol,
.article-content h2 + p,
.article-content h2 + ul,
.article-content h2 + ol,
.article-content h3 + p,
.article-content h3 + ul,
.article-content h3 + ol,
.article-content h4 + p,
.article-content h4 + ul,
.article-content h4 + ol {
  margin-top: 0.5em;
}
.article-content p,
.article-content ul,
.article-content ol {
  font: 400 normal 18px/1.5 "Fieldwork", Helvetica, Arial, sans-serif;
  color: #555;
}
@media screen and (max-width: 767px) {
  .article-content p,
  .article-content ul,
  .article-content ol {
    font-size: 17px;
  }
}
.article-content p + p,
.article-content p + ul,
.article-content p + ol,
.article-content ul + p,
.article-content ul + ul,
.article-content ul + ol,
.article-content ol + p,
.article-content ol + ul,
.article-content ol + ol {
  margin-top: 1em;
}
.article-content p + h1,
.article-content p + h2,
.article-content p + h3,
.article-content p + h4,
.article-content ul + h1,
.article-content ul + h2,
.article-content ul + h3,
.article-content ul + h4,
.article-content ol + h1,
.article-content ol + h2,
.article-content ol + h3,
.article-content ol + h4 {
  margin-top: 1.5em;
}
.article-content ul,
.article-content ol {
  margin-left: 2em;
}
.article-content li + li {
  margin-top: 0.25em;
}
.article-content img {
  display: block;
  margin: 1.5em 0;
  max-width: 100%;
  height: auto;
}
.article-content blockquote {
  margin: 3em;
  padding: 0;
}
@media screen and (max-width: 767px) {
  .article-content blockquote {
    margin: 1.5em 1em;
  }
}
.article-content iframe {
  width: 100%;
  min-height: 535px;
}
.article-content .wp-block-image {
  margin-left: 0;
  margin-right: 0;
}
.article-content pre {
  overflow: auto;
  padding: 20px;
  background-color: #f0f0f0;
}
.article-content-accordion {
  margin-top: 20px;
}
.article-content-accordion .accordion-link {
  margin-top: 15px;
}
.article-content-accordion .accordion-target {
  margin-top: 0.3em;
}

.not-found {
  text-align: center;
}

.video-embed {
  padding: 0 40px 100px;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  .video-embed {
    padding: 20px 20px 60px;
  }
}

.contact-form {
  width: calc(100% - 80px);
  max-width: 800px;
  margin: 0 auto 100px;
  font: 400 normal 18px/1.5 "Fieldwork", Helvetica, Arial, sans-serif;
  color: #555;
}
@media screen and (max-width: 767px) {
  .contact-form {
    font-size: 17px;
  }
}
.contact-form div.wpforms-container-full button[type=submit] {
  font: 700 normal 18px/1.2 "Fieldwork", Helvetica, Arial, sans-serif !important;
  letter-spacing: 0.02em !important;
  text-decoration: none !important;
  color: #fff !important;
  background-color: #50c11e !important;
  display: inline-block !important;
  padding: 0.9em 2em !important;
  border: 0 !important;
  transition: background-color 0.3s, color 0.3s !important;
  text-align: center !important;
  border-radius: 30px !important;
  height: auto !important;
}
.contact-form div.wpforms-container-full button[type=submit]:hover {
  background-color: #3e9517 !important;
  color: #fff !important;
  cursor: pointer !important;
  outline: 0 !important;
}

.wpcf7-form {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}
.wpcf7-form div {
  margin-bottom: 10px;
}
@media screen and (max-width: 767px) {
  .wpcf7-form div {
    margin-bottom: 5px;
  }
}
.wpcf7-form div.third {
  width: calc((100% / 3) - 15px / 2);
}
@media screen and (max-width: 767px) {
  .wpcf7-form div.third {
    width: 100%;
  }
}
.wpcf7-form div.half {
  width: calc(50% - 5px);
}
@media screen and (max-width: 767px) {
  .wpcf7-form div.half {
    width: 100%;
  }
}
.wpcf7-form div.full {
  width: 100%;
}
.wpcf7-form label,
.wpcf7-form .wpcf7-list-item-label {
  font: 400 normal 18px/1.5 "Fieldwork", Helvetica, Arial, sans-serif;
  color: #555;
}
@media screen and (max-width: 767px) {
  .wpcf7-form label,
  .wpcf7-form .wpcf7-list-item-label {
    font-size: 17px;
  }
}
.wpcf7-form label {
  margin: 0.7em 0 0.2em !important;
  display: block;
}
.wpcf7-form label + br {
  display: none;
}
.wpcf7-form .wpcf7-list-item {
  display: block !important;
  margin: 0.2em 0 0 0 !important;
}
.wpcf7-form .wpcf7-list-item.first {
  margin-top: 0.5em !important;
}
.wpcf7-form .wpcf7-list-item .wpcf7-list-item-label {
  margin-left: 0.3em;
}
.wpcf7-form input[type=text],
.wpcf7-form input[type=email],
.wpcf7-form input[type=tel],
.wpcf7-form select,
.wpcf7-form textarea {
  border: 0;
  border-radius: 0;
  box-sizing: border-box;
  box-shadow: none;
  appearance: none;
  -webkit-appearance: none;
  width: 100%;
  margin: 0;
  padding: 0.8em 1em 0.7em;
  background-color: #fff;
  font: 400 normal 18px/1.5 "Fieldwork", Helvetica, Arial, sans-serif;
  color: #555;
  color: #333;
  transition: border-color 0.3s;
  border-radius: 3px;
  border: #e0e0e0 solid 2px;
}
@media screen and (max-width: 767px) {
  .wpcf7-form input[type=text],
  .wpcf7-form input[type=email],
  .wpcf7-form input[type=tel],
  .wpcf7-form select,
  .wpcf7-form textarea {
    font-size: 17px;
  }
}
.wpcf7-form input[type=text]:focus,
.wpcf7-form input[type=email]:focus,
.wpcf7-form input[type=tel]:focus,
.wpcf7-form select:focus,
.wpcf7-form textarea:focus {
  outline: 0;
  border-color: #50c11e;
}
.wpcf7-form select {
  background-image: url("assets/images/global/select-arrow.svg");
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: right center;
  padding-right: 3em;
}
.wpcf7-form textarea {
  resize: none;
  height: 200px;
}
.wpcf7-form input[type=submit] {
  margin: 20px auto 0;
  display: block;
}

.form {
  padding-bottom: 40px;
}
.form.fill {
  background-color: #f1f1f1;
  margin-bottom: 100px;
}
@media screen and (max-width: 767px) {
  .form.fill {
    margin-bottom: 60px;
  }
}
.form-intro {
  padding: 60px 80px 40px;
  margin: 0 auto;
  width: calc(100% - 80px);
  max-width: 800px;
  text-align: center;
}
@media screen and (max-width: 767px) {
  .form-intro {
    padding-left: 0;
    padding-right: 0;
  }
}

.woocommerce-page .post-content {
  max-width: 1200px;
  margin: 0 auto;
  font-family: "Fieldwork", Helvetica, Arial, sans-serif;
  padding-bottom: 100px;
}
.woocommerce-page .post-content:after {
  content: "";
  display: table;
  clear: both;
}
.woocommerce-page .post-content > h1 {
  padding-bottom: 40px;
}
.woocommerce-page .woocommerce-tabs,
.woocommerce-page .products.related,
.woocommerce-page .product_meta {
  display: none !important;
  opacity: 0 !important;
  visibility: hidden !important;
  pointer-events: none !important;
}
.woocommerce-page .entry-header,
.woocommerce-page .woocommerce-products-header,
.woocommerce-page .wp-block-heading {
  padding-bottom: 40px;
}
.woocommerce-page .page-title,
.woocommerce-page .entry-title {
  font: 700 normal 50px/1.1 "Fieldwork", Helvetica, Arial, sans-serif;
  color: #50c11e;
  text-align: left;
}
@media screen and (max-width: 1200px) {
  .woocommerce-page .page-title,
  .woocommerce-page .entry-title {
    font-size: 48px;
  }
}
@media screen and (max-width: 1023px) {
  .woocommerce-page .page-title,
  .woocommerce-page .entry-title {
    font-size: 44px;
  }
}
@media screen and (max-width: 767px) {
  .woocommerce-page .page-title,
  .woocommerce-page .entry-title {
    font-size: 38px;
  }
}
.woocommerce-page .product_title {
  font: 700 normal 50px/1.1 "Fieldwork", Helvetica, Arial, sans-serif;
  color: #50c11e;
}
@media screen and (max-width: 1200px) {
  .woocommerce-page .product_title {
    font-size: 48px;
  }
}
@media screen and (max-width: 1023px) {
  .woocommerce-page .product_title {
    font-size: 44px;
  }
}
@media screen and (max-width: 767px) {
  .woocommerce-page .product_title {
    font-size: 38px;
  }
}
.woocommerce-page.woocommerce-shop .main, .woocommerce-page.single-product .main {
  width: calc(100% - 80px);
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 220px 0 160px;
}
@media screen and (max-width: 767px) {
  .woocommerce-page.woocommerce-shop .main, .woocommerce-page.single-product .main {
    padding-top: 140px;
    width: calc(100% - 60px);
  }
}
.woocommerce-page .duration {
  display: none !important;
  opacity: 0 !important;
  visibility: hidden !important;
  pointer-events: none !important;
}
.woocommerce-page .button {
  margin-top: 0;
}
.woocommerce-page .place-order .button {
  margin-top: 1em;
}
.woocommerce-page .woocommerce-product-details__short-description {
  margin-bottom: 40px;
}
.woocommerce-page .woocommerce-product-details__short-description p + p,
.woocommerce-page .woocommerce-product-details__short-description p + ul,
.woocommerce-page .woocommerce-product-details__short-description p + ol,
.woocommerce-page .woocommerce-product-details__short-description ul + p,
.woocommerce-page .woocommerce-product-details__short-description ul + ul,
.woocommerce-page .woocommerce-product-details__short-description ul + ol,
.woocommerce-page .woocommerce-product-details__short-description ol + p,
.woocommerce-page .woocommerce-product-details__short-description ol + ul,
.woocommerce-page .woocommerce-product-details__short-description ol + ol {
  margin-top: 1.5em;
}
.woocommerce-page .woocommerce-product-details__short-description p + h1,
.woocommerce-page .woocommerce-product-details__short-description p + h2,
.woocommerce-page .woocommerce-product-details__short-description p + h3,
.woocommerce-page .woocommerce-product-details__short-description ul + h1,
.woocommerce-page .woocommerce-product-details__short-description ul + h2,
.woocommerce-page .woocommerce-product-details__short-description ul + h3,
.woocommerce-page .woocommerce-product-details__short-description ol + h1,
.woocommerce-page .woocommerce-product-details__short-description ol + h2,
.woocommerce-page .woocommerce-product-details__short-description ol + h3 {
  margin-top: 2em;
}
.woocommerce-page .woocommerce-product-details__short-description h2 {
  font: 700 normal 20px/1.4 "Fieldwork", Helvetica, Arial, sans-serif;
  letter-spacing: 0.02em;
  color: #333;
}
@media screen and (max-width: 1200px) {
  .woocommerce-page .woocommerce-product-details__short-description h2 {
    font-size: 19px;
  }
}
@media screen and (max-width: 1023px) {
  .woocommerce-page .woocommerce-product-details__short-description h2 {
    font-size: 18px;
  }
}
@media screen and (max-width: 767px) {
  .woocommerce-page .woocommerce-product-details__short-description h2 {
    font-size: 17px;
  }
}
.woocommerce-page .woocommerce-billing-fields p {
  margin: 10px 0 25px;
}
.woocommerce-page .woocommerce-billing-fields #billing_address_1_field {
  margin-bottom: 0;
}
.woocommerce-page .order-again {
  margin-bottom: 30px;
}
.woocommerce-page .with-empty-cart-icon:before {
  display: none !important;
  opacity: 0 !important;
  visibility: hidden !important;
  pointer-events: none !important;
}

.wp-block-woocommerce-cart,
.wp-block-woocommerce-checkout {
  font-family: "Fieldwork", Helvetica, Arial, sans-serif;
}
.wp-block-woocommerce-cart .wc-block-components-product-name,
.wp-block-woocommerce-checkout .wc-block-components-product-name {
  font-size: 20px;
}

.wc-block-components-product-metadata__description {
  display: none !important;
  opacity: 0 !important;
  visibility: hidden !important;
  pointer-events: none !important;
}

ul.products {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}
ul.products li.product {
  width: 33.3333333333%;
  background-color: #fff;
  font-family: "Fieldwork", Helvetica, Arial, sans-serif;
  padding: 0 10px;
}
@media screen and (max-width: 1023px) {
  ul.products li.product {
    width: 50%;
  }
}
@media screen and (max-width: 767px) {
  ul.products li.product {
    width: 100%;
  }
}
ul.products li.product .woocommerce-loop-product__title {
  font-weight: 700;
}