//==============================
// Form
//==============================

@mixin input-reset {
  border: 0;
  border-radius: 0;
  box-sizing: border-box;
  box-shadow: none;
  appearance: none;
  -webkit-appearance: none;
}

select {
  &::-ms-expand {
    display: none;
  }
}

// placeholder and autofill
// input,
// textarea {
//   &::-webkit-input-placeholder {
//     color: $charcoal;
//   }

//   &:-moz-placeholder {
//     color: $charcoal;
//   }

//   &::-moz-placeholder {
//     color: $charcoal;
//   }

//   &:-ms-input-placeholder {
//     color: $charcoal;
//   }

//   &:-webkit-autofill {
//     -webkit-box-shadow: 0 0 0px 1000px #444 inset;
//     -webkit-text-fill-color: $charcoal !important;
//   }
// }
