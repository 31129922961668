//==============================
// Large Image
//==============================

.large-image {
  display: block;
  width: calc(100% - 80px);
  max-width: 1400px;
  margin: 0 auto 100px;
  height: auto;

  @include bp(xs) {
    width: calc(100% - 40px);
  }
}