//==============================
// Google Map
//==============================

.google-map {

  iframe {
    width: 100%;
    height: 75vw;
    max-height: 450px;
  }
}
