//==============================
// Diptych
//==============================

.diptych {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1600px;
  padding: 0 30px 100px;

  @include bp(m) {
    padding: 0 20px 60px;
  }

  @include bp(s) {
    padding-left: 0;
    padding-right: 0;
  }

  &-content {
    width: 45%;
    padding: 120px 100px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: flex-start;

    @include bp(l) {
      padding: 60px 80px;
    }

    @include bp(m) {
      padding: 40px;
    }

    @include bp(s) {
      width: 100%;
      max-width: 500px;
      margin: 0 auto;
      padding: 30px;
      // text-align: center;
    }

    > * {
      width: 100%;
    }

    .button,
    .text-button {
      width: auto;
    }

    &.center {
      text-align: center;
    }

    &-icon {
      height: 80px;
      margin: 0 auto 15px 2px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      @include bp(m) {
        height: 70px;
      }
      
      svg,
      img {
        width: auto;
        height: 100%;
      }
    }
  }

  &-image {
    width: 55%;
    min-height: 550px;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 10px;
    // box-shadow: 0 10px 50px -10px rgba(0,0,0,.3);

    @include bp(m) {
      min-height: 40vw;
      // box-shadow: 0 5px 30px -5px rgba(0,0,0,.5);
    }

    @include bp(s) {
      width: calc(100% - 30px);
      height: 50vw;
      min-height: 250px;
      max-width: 600px;
      margin: 0 auto;
      order: -1;
      border-radius: 5px;
    }
  }

  &:first-child {
    padding-top: 40px;
  }

  &-group {

    .diptych:nth-child(even) {

      .diptych-content {
        order: -1;

        @include bp(l-up) {
          // padding: 80px 7% 80px calc(25% - 140px);
        }
      }
    }
  }
}
