//==============================
// Details
//==============================

.details {

  &-items {
    width: calc(100% - 80px);
    max-width: 1000px;
    margin: 0 auto 80px;

    @include bp(xs) {
      width: calc(100% - 40px);
    }
  }

  &-item {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    border-radius: 10px;
    border: $light-grey solid 2px;
    margin-bottom: 30px;

    &-details {
      width: 62% !important;
      padding: 25px 50px 30px 35px !important;

      @include bp(s) {
        width: 100% !important;
        padding: 20px 25px 25px !important;
      }

      h3 {
        @include title3;

        + p {
          margin-top: .5em;
        }
      }

      p {

        + p {
          margin-top: 0.5em !important;
        }
      }
    }

    &-prices {
      width: 38%;
      padding: 23px 35px;
      background-color: $light-grey;

      @include bp(s) {
        width: 100%;
        margin-left: 0;
        padding: 20px 25px;
      }

      p {
        font-weight: 700;

        + p {
          margin-top: .5em;
        }
      }

      ul {
        margin-left: .8em;
        @include text;
      }
    }
  }
}
