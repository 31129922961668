//==============================
// Footer
//==============================

.footer {
  background: $black;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 60px 0 40px;
  color: $white;
  font: 300 normal 16px/1.4 $font-primary;
  text-align: center;
  clear: both;

  @include bp(s) {
    padding: 30px 0;
  }

  address {
    line-height: 1.2em;
  }

  a {
    color: inherit;
    text-decoration: none;
    display: inline-block;
    border-bottom: 1px solid transparent;
  }

  &-copyright {
    margin: 10px 0 15px;
    padding: 0;
    display: flex;
    flex-flow: row nowrap;

    @include bp(xs) {
      flex-direction: column;
    }

    li {
      list-style-type: none;
      margin-right: 15px;
      margin-top: .4em;
      padding-right: 15px;
      border-right: currentColor solid 1px;

      @include bp(xs) {
        margin-right: 0;
        padding-right: 0;
        border-right: 0;
      }

      &:last-child {
        margin-right: 0;
        padding-right: 0;
        border-right: 0;
      }
    }

    a {

      &:hover {
        border-bottom-color: currentColor;
      }
    }
  }

  &-logo {
    
    svg {
      height: 120px;
      width: auto;
      margin-bottom: 20px;
      fill: $white;

      @include bp(s) {
        height: 80px;
      }
    }
  }

  &-social {
    display: flex;

    li {
      list-style-type: none;
    }

    a {
      display: block;
      width: 40px;
      height: 40px;
      position: relative;

      svg {
        @include centered;
        fill: $white;
        height: 50%;
        width: auto;
      }

      svg,
      path,
      circle {
        transition: fill .3s;
      }

      path,
      circle {
        fill: $white;
      }

      &:hover {
        
        svg,
        path,
        circle {
          fill: #999;
        }
      }
    }
  }
}

