//==============================
// Mixins
//==============================

// Breakpoints
@mixin bp($bp) {
  @if $bp == xxl {
    @media screen and (max-width: 1680px) { @content; }
  }
  @if $bp == xl {
    @media screen and (max-width: 1500px) { @content; }
  }
  @if $bp == l {
    @media screen and (max-width: 1200px) { @content; }
  }
  @if $bp == m {
    @media screen and (max-width: 1023px) { @content; }
  }
  @if $bp == s {
    @media screen and (max-width: 767px) { @content; }
  }
  @if $bp == xs {
    @media screen and (max-width: 480px) { @content; }
  }
  @if $bp == xxs {
    @media screen and (max-width: 340px) { @content; }
  }

  @if $bp == l-up {
    @media screen and (min-width: 1201px) { @content; }
  }

  @if $bp == landscape {
    @media screen and (orientation:landscape) { @content; }
  }
  @if $bp == portrait {
    @media screen and (orientation:portrait) { @content; }
  }

  @if $bp == retina {
    @media (min-device-pixel-ratio: 2), (min-resolution: 192dpi) { @content; }
  }
}

// Font declaration
@mixin font($font, $file, $weight, $style) {
  @font-face {
    font-family: '#{$font}';
    src: url('assets/fonts/#{$file}');
    font-weight: $weight;
    font-style: $style;
  }
}

@mixin placeholder($autofill-bg, $autofill-color) {
  &::-webkit-input-placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }

  &:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px $autofill-bg inset;
    -webkit-text-fill-color: $autofill-color !important;
  }
}

@mixin fillcontainer {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

@mixin centered {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@mixin square {

  &:before {
    content: ''; 
    padding-top: 100%;
    display: table;
  }
}

@mixin clearfix {
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}

@mixin bg-cover {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

@mixin img-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@mixin hide {
  display: none !important;
  opacity: 0 !important;
  visibility: hidden !important;
  pointer-events: none !important;
}

