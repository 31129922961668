//==============================
// Buttons
//==============================

@mixin button-text {
  font: 700 normal 18px/1.2 $font-primary;
  letter-spacing: .02em;
  text-decoration: none;
}

.button {
  @include button-text;
  color: $white;
  background-color: $green;
  display: inline-block;
  padding: .9em 2em;
  border: 0;
  transition: background-color .3s, color .3s;
  margin-top: 1.5em;
  text-align: center;
  border-radius: 30px;

  &:hover {
    background-color: darken($green, 10%);
    color: $white;
    cursor: pointer;
    outline: 0;
  }
}

.text-button {
  @include button-text;
  margin-top: 1em;
  padding-right: .6em;
  display: inline-block;
  color: $green;
  position: relative;
  transition: color .3s;

  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: 45%;
    border-top: currentColor solid 2px;
    border-right: currentColor solid 2px;
    width: .25em;
    height: .25em;
    transform-origin: left center;
    transform: rotate(45deg) translate(-50%, -50%);
    transition: border-color .3s;
  }

  &:hover {
    color: darken($green, 10%);
  }
}