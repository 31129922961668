//==============================
// Variables
//==============================


// Colors
//------------------------------

$selection: #b3d4fc;

$white: #fff;
$black: #111;
$green: #50c11e;
$charcoal: #333;
$red: #ff0000;
$light-grey: #f1f1f1;

$font-primary: 'Fieldwork', Helvetica, Arial, sans-serif;
$stoneburg: 'Stoneburg', Georgia, Times New Roman, serif;

$grid-gutter: 30px;

$tile-gutter: 60px;

$gallery-gutter: 15px;
$gallery-gutter-s: 10px;

$form-gutter: 5px;

$header-height: 230px;
$header-bar-height: 70px;
$header-transition-time: .4s;

// not actually used to set height of footer, just for setting min-height of .post
$footer-height: 345px;
$footer-height-s: 285px;
$footer-height-xs: 330px;