//==============================
// Home
//==============================

.home {

  &-services {

    &-intro {
      text-align: center;
      padding: 60px 40px 20px;

      @include bp(s) {
        padding: 40px 20px 0;
      }
    }

    &-items {
      display: flex;
      flex-flow: row wrap;
      margin: 30px 0;
      padding-top: 30px;
      border-top: #eee solid 2px;
      text-align: left;

      @include bp(m) {
        margin: 20px 0 0;
      }

      li {
        list-style-type: none;
        margin-bottom: 10px;
        width: 50%;
        font: 700 normal 14px/1.2 $font-primary;
        letter-spacing: .06em;
        text-transform: uppercase;
        color: #555;
        position: relative;
        padding-left: 1em;
        padding-right: 1em;


        @include bp(m) {
          font-size: 13px;
        }

        @include bp(s) {
          font-size: 12px;
        }

        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: .3em;
          margin-right: 5px;
          width: 6px;
          height: 6px;
          background: $green;
          border-radius: 50%;
        }
      }
    }
  }

  &-instagram {
    padding: 100px 40px;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
    text-align: center;

    @include bp(s) {
      padding: 40px 20px;
    }

    @include bp(xs) {
      padding: 0 10px;
    }

    svg {
      display: block;
      width: auto;
      height: 40px;
      margin: 0 auto 10px;

      @include bp(s) {
        height: 30px;
      }
    }

    &-title {
      font: 700 normal 40px/1 $font-primary;
      color: $green;
      margin-bottom: .7em;

      @include bp(s) {
        font-size: 7vw;
      }

      a {
        color: inherit;
        text-decoration: none;
        display: inline-block;

        &:hover {
          color: $green;
        }
      }
    }
  }
}

// Custom IG styles / fixes
#sb_instagram .sbi_photo img {
  height: 100% !important;
  object-fit: cover;
}

.sbi_photo_wrap {
  border-radius: 5px;
  overflow: hidden;
}