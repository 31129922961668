//==============================
// WooCommerce
//==============================

.woocommerce-page {

  .post-content {
    max-width: 1200px;
    margin: 0 auto;
    font-family: $font-primary;
    @include clearfix;
    padding-bottom: 100px;

    > h1 {
      padding-bottom: 40px;
    }
  }

  .woocommerce-tabs,
  .products.related,
  .product_meta {
    @include hide;
  }

  .entry-header,
  .woocommerce-products-header,
  .wp-block-heading {
    padding-bottom: 40px;
  }

  .page-title,
  .entry-title {
    @include title1;
    text-align: left;
  }

  .product_title {
    @include title1;
  }

  &.woocommerce-shop,
  &.single-product {

    .main {
      width: calc(100% - 80px);
      max-width: 1200px;
      margin-left: auto;
      margin-right: auto;
      padding: 220px 0 160px;

      @include bp(s) {
        padding-top: 140px;
        width: calc(100% - 60px);
      }
    }
  }

  .duration {
    @include hide;
  }

  .button {
    margin-top: 0;
  }

  .place-order {

    .button {
      margin-top: 1em;
    }
  }

  .woocommerce-product-details__short-description {
    margin-bottom: 40px;

    p,
    ul,
    ol {

      + p,
      + ul,
      + ol {
        margin-top: 1.5em;
      }

      + h1,
      + h2,
      + h3 {
        margin-top: 2em;
      }
    }

    h2 {
      @include title4;
    }

    h3 {
      // @include title5;
    }
  }

  .woocommerce-billing-fields {

    p {
      margin: 10px 0 25px;
    }

    #billing_address_1_field {
      margin-bottom: 0;
    }
  }

  .order-again {
    margin-bottom: 30px;
  }

  .with-empty-cart-icon {

    &:before {
      @include hide;
    }
  }
}

.wp-block-woocommerce-cart,
.wp-block-woocommerce-checkout {
  font-family: $font-primary;

  .wc-block-components-product-name {
    font-size: 20px;
  }
}

.wc-block-components-product-metadata__description {
  @include hide;
}

ul.products {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;

  li.product {
    width: calc(100% / 3);
    background-color: $white;
    font-family: $font-primary;
    padding: 0 10px;

    // @include bp(l) {
    //   width: calc(100% / 3);
    // }

    @include bp(m) {
      width: 50%;
    }

    @include bp(s) {
      width: 100%;
    }

    // img {
    //   height: 400px;
    //   width: auto;
    //   max-width: 80%;
    //   object-fit: contain;

    //   @include bp(xl) {
    //     height: 300px;
    //   }
    // }

    .woocommerce-loop-product__title {
      font-weight: 700;
    }
  }
}
