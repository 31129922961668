//==============================
// RSS Retriever
//==============================

.wp_rss_retriever {

  .wp_rss_retriever_list {
    margin: 0 auto !important;
  }

  &_image {
    order: -1;
    width: auto !important;
    height: 250px !important;
    float: none !important;
    margin-right: 0 !important;
    margin-bottom: 20px !important;

    @include bp(xs) {
      height: 200px !important;
    }
    
    img {
      width: 100% !important;
      max-width: 100% !important;
      min-height: 0 !important;
      min-width: 0 !important;
    }
  }

  &_title,
  h3 {
    @include title3;
    margin-bottom: 0 !important;
  }

  &_item {
    display: none;

    &_wrapper {
      display: flex;
      flex-flow: column nowrap;
      text-decoration: none;
    }
  }

  &_credits {
    display: none;
  }
}

.stu {

  .wp_rss_retriever {
    max-width: 1400px;
    margin: 0 auto;
    position: relative;

    // covers up centerPadding so arrows can be positioned on top 
    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 80px;
      height: 100%;
      top: 0;
      z-index: 2;

      @include bp(m) {
        display: none;
      }
    }

    &:before {
      background-color: #f0f0f0;
      left: 0;
    }

    &:after {
      background-color: #f0f0f0;
      right: 0;
    }

    &:hover {
      cursor: grab;
    }

    &:active {
      cursor: grabbing;
    }

    &_item {
      margin: 0 10px;
    }

    .slick {

      &-arrow {
        position: absolute;
        z-index: 3;
        width: 80px;
        height: 100%;
        top: 0;
        text-indent: -9999px;
        @include input-reset;
        background-color: transparent;
        background-position: center 30%;
        background-size: 20% auto;
        background-repeat: no-repeat;
        opacity: .5;
        transition: opacity .3s;

        &:hover {
          outline: 0;
          cursor: pointer;
          opacity: 1;
        }
      }

      &-prev {
        left: 0;
        background-image: url('assets/images/global/slick-arrow-left.svg');
      }

      &-next {
        right: 0;
        background-image: url('assets/images/global/slick-arrow-right.svg');
      }
    }
  }
}

.grid {

  .wp_rss_retriever {

    &_list {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      margin: 0 auto;
      max-width: 1200px;
    }

    &_item {
      width: calc((100% / 3) - (#{$grid-gutter * 2} / 3));
      margin-right: $grid-gutter;
      margin-bottom: 60px;
      text-align: center;

      @include bp(m) {
        width: calc(50% - (#{$grid-gutter} / 2));
      }

      @include bp(s) {
        width: 100%;
        margin-right: 0;
        max-width: 400px;
        margin-bottom: 40px;
      }

      @include bp(xs) {
        margin-right: 0;
      }

      &:nth-child(3n) {
        margin-right: 0;

        @include bp(m) {
          margin-right: $grid-gutter;
        }

        @include bp(s) {
          margin-right: 0;
        }
      }

      &:nth-child(2n) {

        @include bp(m) {
          margin-right: 0;
        }
      }
    }
  }
}