//==============================
// Photo & Video
//==============================

.video-embed {
  padding: 0 40px 100px;
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;

  @include bp(s) {
    padding: 20px 20px 60px;
  }
}
