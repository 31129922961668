//==============================
// Gallery
//==============================

.gallery {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 0 $gallery-gutter;

  &-item {
    margin-bottom: $gallery-gutter;
    @include bg-cover;
    border-radius: 10px;

    @include bp(s) {
      width: 100% !important;
      height: 68vw !important;
      border-radius: 5px;
    }

    &:nth-child(5n+1),
    &:nth-child(5n+2) {
      width: calc(50% - (#{$gallery-gutter} / 2));
      height: 34vw;
    }

    &:nth-child(5n+3),
    &:nth-child(5n+4),
    &:nth-child(5n+5) {
      width: calc((100% / 3) - (#{$gallery-gutter * 2} / 3));
      height: 23vw;
    }
  }
}
