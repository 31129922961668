/*!
Theme Name: The Sauce Pot Studios
Author: Robert Pessagno
Version: 3.1
Template: storefront
Description: This is a custom child theme for WooThemes StoreFront theme
*/

//==============================
// Main
//==============================


// Global
//------------------------------

@import 'global/mixins';
@import 'global/fonts';
@import 'global/variables';
@import 'global/normalize';
@import 'global/global';


// Libraries
//------------------------------

@import 'lib/slick';
@import 'lib/contact-form-7';
@import 'lib/jquery-ui';


// Components
//------------------------------

@import 'components/typography';
@import 'components/forms';
@import 'components/buttons';
@import 'components/header';
@import 'components/footer';
@import 'components/hero';
@import 'components/intro';
@import 'components/grid';
@import 'components/tiles';
@import 'components/diptych';
@import 'components/gallery';
@import 'components/large-image';
@import 'components/details';
@import 'components/rss-retriever';
@import 'components/google-map';
@import 'components/alert';


// Templates
//------------------------------

@import 'templates/home';
@import 'templates/article';
@import 'templates/not-found';
// @import 'templates/product';
@import 'templates/photo-video';
@import 'templates/contact';
@import 'templates/woocommerce';


// Print
//------------------------------
@import 'global/print';
