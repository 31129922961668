//==============================
// Alert
//==============================

.alert {
  padding: 60px 140px;
  margin: 80px auto;
  width: calc(100% - 80px);
  max-width: 840px;
  position: relative;
  background: transparentize($red, .9);
  border: $red solid 2px;
  border-radius: 5px;
  text-align: center;

  @include bp(m) {
    max-width: 700px;
    padding: 80px 100px;
  }

  @include bp(s) {
    padding: 60px;
    margin: 60px auto;
  }

  @include bp(xs) {
    width: calc(100% - 40px);
    padding: 40px 25px;
  }

  &:before {
    content: 'i';
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);
    width: 60px;
    height: 56px;
    padding-top: 4px;
    background-color: $red;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font: 400 normal 30px/1 $font-primary;
    color: $white;

    @include bp(s) {
      width: 50px;
      height: 46px;
      font-size: 25px;
    }
  }

  .title1,
  .title2,
  .title3,
  .title4 {
    color: $red;
  }

  + .intro {
    padding-top: 0 !important;
  }
}
