//==============================
// Header
//==============================

.header {
  position: fixed;
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 2000;
  height: 120px;
  padding: 0 30px;
  background-color: $black;
  transition: width $header-transition-time, background-color $header-transition-time, transform $header-transition-time, box-shadow $header-transition-time;

  @include bp(m) {
    height: 90px;
    padding: 0 20px 0 25px;
  }

  // &.scroll {
  //   transform: translateY(-100%);
  // }

  &.open {

    .header-logo {

      .aaron-logo-white {
        fill: $charcoal;
      }
    }

    #menu-main {

      .menu-item {
        color: $charcoal;

        &:after {
          border-color: $charcoal;
        }
      }
    }

    .header-shop-item {
      color: $charcoal;

      svg {
        fill: $charcoal;
      }
    }
  }

  &-logo {
    position: relative;
    z-index: 101;
    margin-right: auto;

    img {
      display: block;
      height: 95px;
      width: auto;

      @include bp(m) {
        height: 70px;
      }

      .aaron-logo-white {
        transition: fill $header-transition-time;
      }
    }
  }

  > .header-nav-utility-wrap {

    @include bp(s) {
      @include hide;
    }
  }

  &-nav {
    position: fixed;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-content: center;
    height: 100%;
    right: 0;
    top: 0;
    width: 55%;
    z-index: 100;
    background-color: #000;
    padding: 0 0 0 8%;
    transform: translateX(100%);
    visibility: hidden;
    transition: opacity .5s, visibility .5s, transform .5s;

    @include bp(xxl) {
      padding-left: 7%;
    }

    @include bp(l) {
      width: 80%;
    }

    @include bp(m) {
      padding-left: 10%;
    }

    @include bp(s) {
      width: 100%;
      padding: 110px 30px 0;
      align-content: flex-start;
    }

    &.open {
      visibility: visible;
      transform: translate(0);
    }

    > .header-nav-utility-wrap {
      display: none;

      @include bp(s) {
        display: flex;
      }
    }

    .menu-main-container {
      width: 37%;

      @include bp(s) {
        margin-bottom: 15px;
      }

      ul li {
        font: 400 normal 48px/1 $font-primary;

        @include bp(xxl) {
          font-size: 2.5vw;
        }

        @include bp(xl) {
          font-size: 34px;
        }

        @include bp(l) {
          font-size: 3.5vw;
        }

        @include bp(m) {
          font-size: 30px;
        }

        @include bp(xs) {
          font-size: 26px;
        }
      }
    }
    .menu-services-container {
      width: 63%;

      ul li {
        font: 700 normal 34px/1 $font-primary;

        @include bp(xxl) {
          font-size: 2vw;
        }

        @include bp(xl) {
          font-size: 28px;
        }

        @include bp(l) {
          font-size: 3vw;
        }

        @include bp(m) {
          font-size: 24px;
        }

        @include bp(xs) {
          font-size: 20px;
        }
      }
    }

    #menu-main,
    #menu-services {
      height: 100%;
    }

    .menu-main-container,
    .menu-services-container {

      @include bp(s) {
        width: 100%;
      }

      ul {
        display: flex;
        flex-flow: column nowrap;

        li {
          list-style-type: none;
          position: relative;

          a {
            display: block;
            padding: 15px 0;
            position: relative;
            text-decoration: none;
            transition: color .2s;
            position: relative;
            z-index: 2;
            color: $white;

            @include bp(s) {
              padding: .4em 0;
            }

            &:hover {
              color: $green;
            }
          }
        }
      }
    }

    &-utility {
      display: flex;
      flex-flow: row nowrap;

      &-wrap {
        display: flex;
        flex-flow: row nowrap;
        margin: 0 30px 0 auto;

        @include bp(s) {
          margin: 20px 0 0 -14px;
        }
      }

      li {
        list-style-type: none;

        a {
          display: block;
          padding: 10px 15px;
          transition: opacity .2s;

          &:hover {
            opacity: .7;
          }
        }

        img {
          display: block;
          height: 20px;
          width: auto;
        }
      }

      + .header-nav-utility {
        border-left: 1px solid #ccc;
        margin-left: 20px;
        padding-left: 20px;
      }

      &-cart {
        position: relative;

        &-count {
          position: absolute;
          top: 0;
          left: calc(100% - 1.8em);
          background-color: $green;
          color: $white;
          text-decoration: none;
          border-radius: 1em;
          min-width: 1.5em;
          padding: 0 .5em;
          height: 1.5em;
          display: flex;
          justify-content: center;
          align-items: center;
          font: 700 normal 12px/1 $font-primary;
        }
      }
    }

    &-button {
      @include input-reset;
      width: 50px;
      height: 50px;
      padding: 0;
      position: relative;
      background-color: transparent !important;
      order: 1;
      z-index: 101;
      outline: 0;

      span {
        display: block;
        width: 30px;
        height: 2px;
        background-color: $white;
        position: absolute;
        left: 50%;
        top: 50%;
        opacity: 1;
        transition: transform $header-transition-time, opacity $header-transition-time, width $header-transition-time, background-color $header-transition-time;

        &.top {
          transform: translate(-50%, -7px) rotate(0deg);
          transform-origin: left top;
        }

        &.middle {
          transform: translate(-50%, 0) rotate(0deg);
        }

        &.bottom {
          transform-origin: left bottom;
          transform: translate(-50%, 7px) rotate(0deg);
        }
      }

      &.open {

        span {
          background: $white;

          &.top {
            transform: translate(-35%, -8px) rotate(45deg);
          }

          &.middle {
            opacity: 0;
          }

          &.bottom {
            transform: translate(-35%, 12px) rotate(-45deg);
          }
        }
      }
    }

    &-overlay {
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: transparentize($black, .5);
      z-index: 100;
      visibility: hidden;
      opacity: 0;
      transition: visibility .3s, opacity .3s;

      &.open {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}
