//==============================
// Hero
//==============================

.hero {
  width: calc(100% - 40px);
  margin: 20px 0 100px 20px;
  overflow: hidden;
  border-radius: 10px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  position: relative;

  @include bp(m) {
    margin-bottom: 60px;
  }

  @include bp(s) {
    width: calc(100% - 30px);
    margin: 15px 0 40px 15px;
    border-radius: 5px;
  }

  &-content {
    width: 45%;
    // padding: 220px 0 140px;
    padding: 10vw 0 8vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $light-grey;

    @include bp(xl) {
      width: 45%;
      // padding: 10vw 0 8vw;
      padding: 140px 0 60px;
    }

    @include bp(l) {
      padding: 140px 0 60px;
      width: 50%;
    }

    @include bp(m) {
      width: 100%;
      padding: 40px 0;
    }

    &-inner {
      max-width: 700px;
      padding: 0 80px;

      @include bp(l) {
        padding: 0 60px;
      }

      @include bp(m) {
        padding: 0 40px;
        margin: 0 auto;
        max-width: 600px;
      }

      @include bp(s) {
        max-width: 500px;
        padding: 0 30px;
      }
    }
  }

  &-media {
    width: 55%;

    @include bp(xl) {
      width: 55%;
    }

    @include bp(l) {
      width: 50%;
    }

    @include bp(m) {
      width: 100%;
      order: -1;
    }

    @include bp(s) {
      top: 40px;
    }
  }

  &-slider {
    height: 100%;

    &-item {
      background-color: #aaa;
      position: relative;

      @include bp(m) {
        height: 50vw;
        min-height: 400px;
      }

      @include bp(s) {
        height: 50vw;
        min-height: 250px;
      }

      img {
        @include centered;
        @include img-cover;
      }
    }

    .slick {

      &-list {
        height: 100%;

        @include bp(m) {
          // border-radius: 15px;
          // box-shadow: 0 5px 30px -5px rgba(0,0,0,.5);
        }

        @include bp(s) {
          // border-radius: 10px;
        }
      }

      &-track {
        height: 100%;
      }

      &-prev {
        display: none !important;
      }

      &-next {
        @include input-reset;
        width: 50px;
        height: 50px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(-50%, -50%);
        background: $white url('assets/images/global/slick-arrow-right.svg') 55% center / auto 35% no-repeat;
        border-radius: 50%;
        text-indent: -9999px;
        box-shadow: 0 5px 20px -5px rgba(0,0,0,.2);
        outline: 0;

        @include bp(m) {
          display: none !important;
        }
      }

      &-dots {
        display: none !important;
      }
    }
  }

  &-title1 {
    font: 700 normal 60px/1 $font-primary;
    letter-spacing: -0.02em;
    color: $green;
    position: relative;
    z-index: 2;
    margin-bottom: 20px;

    @include bp(xxl) {
      font-size: 50px;
    }

    @include bp(l) {
      font-size: 46px;
    }

    @include bp(m) {
      font-size: 42px;
    }

    @include bp(s) {
      font-size: 34px;
    }
  }

  &-icon {
    width: 80px;
    height: 80px;
    background-color: $green;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    position: relative;
    z-index: 3;
    margin-bottom: 20px;
    box-shadow: 0 0 50px transparentize(#000, .5);

    @include bp(s) {
      height: 60px;
      width: 60px;
      margin-bottom: 10px;
    }

    @include bp(xs) {
      height: 50px;
      width: 50px;
    }

    svg {
      width: auto;
      height: auto;
      max-width: 50%;
      max-height: 80%;
    }
  }

  &-arrow {
    @include input-reset;
    position: absolute;
    z-index: 3;
    left: 50%;
    bottom: 0;
    width: 50px;
    height: 50px;
    background: transparent;
    transform: translateX(-50%);

    &:after {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -20px) rotate(-45deg);
      border-left: $white solid 3px;
      border-bottom: $white solid 3px;
      width: 20px;
      height: 20px;
      transition: transform .2s;
    }

    &:hover {
      outline: 0;
      cursor: pointer;

      &:after {
        transform: translate(-50%, -15px) rotate(-45deg);
      }
    }
  }
}
